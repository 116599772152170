<template>
  <SlContextMenu
    id="chart-context-menu"
    ref="contextMenu"
    :options="options"
    @option-click="handleOptionClick"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  demandChartOptions,
  demandChartActionsMap
} from '@/config/shared/contextMenu.config';
import { colKeys } from '@/config/demand/table.config';
import { forecastingKeys } from '@/config/demand/demandSettings.config';
import { dateByLocaleKey } from '@/helpers/locale/localeDate';

export default {
  name: 'ChartContextMenu',
  data() {
    return {
      periodDate: null,
      periodIndex: null
    };
  },
  computed: {
    ...mapGetters({
      pKnown: 'project/pKnown'
    }),
    options() {
      const options = demandChartOptions(this, dateByLocaleKey(this.periodDate));

      return options.filter(option => {
        return (this.periodDate && this.periodIndex <= this.pKnown) || option.group === 'canvas';
      });
    }
  },
  methods: {
    ...mapActions({
      updateTable: 'demand/table/updateTable',
      updateSettings: 'demand/tree/updateSettings'
    }),
    show(...args) {
      this.setPeriod(args[0].context);
      this.$refs.contextMenu.show(...args);
    },
    setPeriod({ chart, dataX }) {
      const labels = chart.data.labels;

      this.periodIndex = Math.round(dataX);
      this.periodDate = labels[this.periodIndex];
    },
    zoomOut({ chart }) {
      chart.resetZoom();
    },
    ignoreActualSalesBefore({ dataX }) {
      this.updateTable({
        col: Math.round(dataX),
        row: {
          name: colKeys.customOutlier.requestKey
        },
        val: 'SetChecked'
      });
    },
    ignoreTrendBefore({ dataX }) {
      this.updateSettings({
        [forecastingKeys.IGNORE_BEFORE]: Math.round(dataX)
      });
    },
    handleOptionClick({ key, context }) {
      const action = demandChartActionsMap[key];

      this[action] && this[action](context);
    }
  }
};
</script>