<template>
  <SlModal
    :id="id"
    :title="$t('CreateSeasonalityPattern.Ui.:Caption')"
    @created="onCreated"
    @on-enter="handleCreate"
    @hide="onModalHide"
  >
    <div class="modal-content save-as-pattern">
      <ValidationObserver
        ref="observer"
        class="pattern-form__row"
      >
        <SlValidate
          v-slot="{ invalid }"
          key="name"
          vid="name"
          :rules="nameRules"
        >
          <SlInput
            v-model="patternNameModel"
            :label="$t('CreateSeasonalityPattern.Ui.lbPatterName')"
            :is-invalid="invalid"
            class="sl-form-text-input"
            required
          />
        </SlValidate>
      </ValidationObserver>
      <SlInput
        v-model="patternLevelModel"
        :label="$t('CreateSeasonalityPattern.Ui.lbActivationLevel')"
        type="number"
        float
        class="sl-form-text-input"
      />
    </div>

    <template #footer>
      <SlModalFooter>
        <SlButton
          variant="secondary"
          color="grey"
          @click="handleCancel"
        >
          {{ $t('Common.Cancel') }}
        </SlButton>
        <SlButton @click="handleCreate">
          {{ $t('Web.Modals.BtnCreate') }}
        </SlButton>
      </SlModalFooter>
    </template>
  </SlModal>
</template>

<script>
import { modal } from '@/mixins/modal';

export default {
  name: 'SaveAsPatternModal',
  mixins: [modal],
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      patternNameModel: '',
      patternLevelModel: 0,
      patternNames: null,
      submitCallback: null
    };
  },
  computed: {
    nameRules() {
      return {
        required: true,
        max: 64,
        'sl_not_includes': {
          values: this.patternNames,
          message: this.$t('Error.ErrorDuplicate')
        }
      };
    }
  },
  methods: {
    onCreated(props) {
      Object.keys(props).forEach(key => {
        if (props[key]) {
          this[key] = props[key];
        }
      });
    },
    onModalHide() {
      this.patternNameModel = '';
      this.patternLevelModel = 0;
      this.patternNames = null;
      this.submitCallback = null;
    },
    async handleCreate() {
      const valid = await this.$refs.observer.validate();

      if (!valid) {
        return;
      }

      if (this.submitCallback) {
        this.submitCallback({
          name: this.patternNameModel,
          level: +this.patternLevelModel
        });

        this.hideModal(this.id);
      }
    },
    handleCancel() {
      this.hideModal(this.id);
    }
  }
};
</script>
<style lang="scss" scoped>
@import "@/style/components/modals/season-pattern/save-as-pattern.scss";
</style>
