<template>
  <div>
    <div v-if="kpiObj">
      <div class="dem-settings__header-row">
        <div class="dem-settings__header">
          {{ $t('Main.Value') }}
        </div>
      </div>
      <div
        v-for="(item, index) of kpiObj"
        :key="index"
        class="dem-settings__row-wrap"
      >
        <SettingsRow
          :title="item.name"
          :value="item.data"
          :editable="false"
        />
      </div>
    </div>
    <DemandSettingsSkeleton v-if="loading" />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SettingsRow from '@/components/Demand/DemandSettings/SettingsRow.vue';
import DemandSettingsSkeleton from '@/components/Skeleton/DemandSettingsSkeleton.vue';

export default {
  name: 'KPItab',
  components: {
    SettingsRow,
    DemandSettingsSkeleton
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState({
      kpiObj: state => state.kpi.kpi
    })
  },
  async beforeMount() {
    try {
      this.loading = true;

      await this.fetchKPI();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchKPI: 'kpi/fetchKPI'
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/tabSettings.scss";
</style>