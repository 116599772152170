<template>
  <div class="forecasting-tab">
    <ForecastingRow
      v-for="condition in settings"
      :key="condition.state + condition.name"
      :name="condition.name"
      :state="condition.state"
      :choice="condition.choice"
      :append-icon="condition.icon"
      :row-key="condition.path"
      :min="condition.min === 0 || condition.min ? condition.min : -1"
      :max="condition.max || -1"
      :step="+condition.step || 1"
      :fixed="condition.fixed || 0"
      :button="condition.button"
      @focus="$emit('form-focus')"
      @select-change="(val) => $emit('select-change', { val, condition })"
    />
  </div>
</template>

<script>
import ForecastingRow from '../ForecastingRow.vue';

export default {
  name: 'ForecastingTab',
  components: {
    ForecastingRow
  },
  props: {
    settings: {
      type: Array,
      required: true
    }
  }
};
</script>
