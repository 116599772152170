<template>
  <div
    ref="treeRef"
    class="tree-wrapper__tree"
    tabindex="-1"
    v-bind="$attrs"
    @focus="handleTreeFocus"
    @blur="handleTreeBlur"
  >
    <div
      class="tree"
      :class="{
        'loading': isFetching
      }"
    >
      <RecycleScroller
        v-slot="{ item, index }"
        :key="reset"
        ref="treeScroller"
        :items="treeItems"
        :item-size="treeItemSize"
        key-field="nodeId"
        class="tree-scroller"
      >
        <TreeVirtualItem
          :ref="`tree-virtual-item-${item.nodeId}`"
          :item="item"
          :index="index"
          :next-item="treeItems[index + 1] || {}"
          :hide-stock="wantHideDistortions"
          :item-icons="getItemIcons(item)"
          :is-active-item="checkIsActive(item)"
          @click.right.native.prevent.stop="handleContextMenu"
          @keyup.native.prevent.stop="handleKeyboardNavigation({
            event: $event,
            nodeId: item.nodeId,
            index
          })"
        />
      </RecycleScroller>
    </div>
    <TreeContextMenu ref="slTreeContextMenu" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import TreeContextMenu from '@/components/Demand/Tree/TreeContextMenu.vue';
import TreeVirtualItem from '@/components/Demand/Tree/TreeVirtualItem.vue';
import { contextMenuAdjustment, fgsActionTypes } from '@/config/shared/contextMenu.config';
import { contextMenuFgs, nodeFlags } from '@/config/shared/fgs.config';
import { noteTypes } from '@/config/shared/note.config';
import { getNoteKey } from '@/helpers/shared/note';
import icons from '@/config/demand/tree/iconsByFlagKey.config';
import { ROOT_NODE_ID } from '@/config/demand/tree/tree.config';
import { keyCodes } from '@/config/utils/statusCodes.config';

export default {
  name: 'Tree',
  components: {
    TreeContextMenu,
    TreeVirtualItem
  },
  data() {
    return {
      icons,
      contextMenuFgs,
      treeFocused: false,
      scrollerVisibleGap: 11,
      treeItemSize: 22,
      navKeyCodes: [keyCodes.arrowDown, keyCodes.arrowUp, keyCodes.arrowRight, keyCodes.arrowLeft]
    };
  },
  provide() {
    return {
      getActionFgs: this.getActionFgs
    };
  },
  computed: {
    ...mapState({
      isFetching: (state) => state.demand.tree.is_fetching,
      activeNode: (state) => state.demand.tree.active_node,
      treeItems: (state) => state.demand.tree.tree || [],
      wantHideDistortions: (state) => state.demand.tree.metadata.wantHideDistortions,
      reset: (state) => state.demand.tree.reset,
      indexScrollTo: (state) => state.demand.tree.index_scroll_to,
      nodeNotes: (state) => state.note.nodeNotes
    }),
    ...mapGetters({
      activeNodeId: 'demand/tree/activeNodeId',
      currentNodeThings: 'demand/tree/activeNodeThings',
      actionsFgs: 'demand/tree/actionsFgs'
    }),
    canEditNode() {
      return this.getActionFgs(contextMenuFgs.ADD_NOTE, fgsActionTypes.ENABLED) || this.getActionFgs(contextMenuFgs.EDIT_NOTE, fgsActionTypes.ENABLED);
    },
    isHasNote() {
      return this.activeNode?.nodeFlags & nodeFlags.HAS_NOTE;
    }
  },
  watch: {
    indexScrollTo(index) {
      this.scrollToActiveNode(index);
    },
    activeNodeId() {
      this.handleSelectNode();
    }
  },
  mounted() {
    this.$refs.treeRef.focus();

    setTimeout(() => this.scrollToActiveNode(this.indexScrollTo));
  },
  methods: {
    ...mapActions({
      fetchNote: 'note/fetchNote',
      setCurrentNodeThing: 'note/setCurrentNodeThing',
      setCurrentNote: 'note/setCurrentNote',
      toggleApprove: 'demand/tree/approve',
      toggleChecked: 'demand/tree/toggleCheckedItemState',
      toggleAttention: 'demand/tree/toggleAttentionItemState',
      setSearchValue: 'demand/tree/setSearchValue',
      setActiveNode: 'demand/tree/setActiveNode',
      openTreeChild: 'demand/tree/openTreeChild',
      hideTreeChild: 'demand/tree/hideTreeChild'
    }),
    checkIsActive(item) {
      if (!this.activeNode) {
        return item.nodeId === ROOT_NODE_ID;
      }

      return item.nodeId === this.activeNode?.nodeId;
    },
    getItemIcons(item) {
      return icons.filter(set => +item[set.type] & set.flag);
    },
    scrollToActiveNode(index) {
      if (index === null || !this.$refs.treeScroller) {
        return;
      }

      // todo find better solution
      const start = this.$refs.treeScroller.$_startIndex;
      const end = this.$refs.treeScroller.$_endIndex;
      const halfGap = Math.round(this.scrollerVisibleGap / 2);
      const isVisible = index > start - halfGap && index < end - halfGap;

      if (!isVisible) {
        const last = Math.floor((end - start) - this.scrollerVisibleGap);
        const scrollTo = index >= last ? index - last : index;

        this.$refs.treeScroller.scrollToItem(scrollTo);
      }
    },
    getActionFgs(action, type) {
      if (typeof action === 'bigint') {
        return !!(BigInt(this.actionsFgs[type] || 0) & action);
      }

      return !!(this.actionsFgs[type] & action);
    },
    handleContextMenu(event) {
      this.$refs.slTreeContextMenu.$refs.contextMenu.showMenu(
        event,
        this.activeNodeId,
        contextMenuAdjustment.pageX,
        contextMenuAdjustment.pageY,
        '.tree-wrapper__tree'
      );
    },
    async handleSelectNode() {
      if (this.isHasNote) {
        await this.fetchNote(this.currentNodeThings);
      }

      this.setSearchValue('');
      this.setCurrentNodeThing(this.currentNodeThings);

      this.setCurrentNote({
        note: this.nodeNotes[getNoteKey(this.currentNodeThings)],
        canEdit: this.canEditNode,
        type: noteTypes.NODE
      });
    },
    handleTreeFocus() {
      this.treeFocused = true;

      this.handleSelectNode();

      this.focusNodeByNodeId(this.activeNodeId);
    },
    handleTreeBlur() {
      this.treeFocused = false;
    },
    focusNodeByNodeId(nodeId) {
      this.$refs[`tree-virtual-item-${nodeId}`]?.$el?.focus();
    },
    handleKeyboardNavigation({ event, nodeId, index }) {
      if (!this.navKeyCodes.includes(event.keyCode)) {
        return;
      }

      const currentNode = this.treeItems[index];
      const nextNode = this.treeItems[index + 1];
      const prevNode = this.treeItems[index - 1];
      const isOpen = currentNode.hasChilds && currentNode.depth < nextNode?.depth;

      if (event.keyCode === keyCodes.arrowDown) {
        if (!nextNode) {
          return;
        }

        this.focusNodeByNodeId(nextNode.nodeId);

        return this.setActiveNode({
          node: nextNode,
          clearSearch: true,
          needScroll: true
        });
      }

      if (event.keyCode === keyCodes.arrowUp) {
        if (!prevNode) {
          return;
        }

        this.focusNodeByNodeId(prevNode.nodeId);

        return this.setActiveNode({
          node: prevNode,
          clearSearch: true,
          needScroll: true
        });
      }

      if (event.keyCode === keyCodes.arrowRight) {
        if (!currentNode.hasChilds || isOpen) {
          return;
        }

        return this.openTreeChild({
          nodeId,
          index
        });
      }

      if (event.keyCode === keyCodes.arrowLeft) {
        if (!currentNode.hasChilds || !isOpen) {
          return;
        }

        this.hideTreeChild({
          nodeId,
          index
        });

        // kostyl for navigation focus after virtual scroller update
        setTimeout(() => {
          this.focusNodeByNodeId(currentNode.nodeId);
        }, 700);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree.scss";
</style>
