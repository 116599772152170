var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getNoteTooltipValue()),expression:"getNoteTooltipValue()"}],ref:"treeItem",staticClass:"tree-item",class:{
    'color--over': _vm.isBlue(),
    'tree-item--leaf': !_vm.hasChilds()
  },attrs:{"tabindex":"0"},on:{"click":function($event){$event.stopPropagation();return _vm.handleLabelClicks($event)},"contextmenu":function($event){if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.handleSelectItem($event)},"mouseup":function($event){if('button' in $event && $event.button !== 1){ return null; }if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey){ return null; }$event.preventDefault();$event.stopPropagation();return _vm.handleMiddleClick($event)}}},[_vm._l((_vm.item.depth),function(depth){return _c('i',{key:depth,staticClass:"tree-item__deep-divider"})}),(_vm.hasChilds())?_c('icon',{staticClass:"size-16 tree-item__arrow",class:{ 'tree-item__arrow--opened': _vm.isOpen() },attrs:{"data":require("@icon/arrow-down.svg")},on:{"click":function($event){$event.stopPropagation();return _vm.handleFolderToggle($event)}}}):_vm._e(),_c('div',{staticClass:"tree-item__label",class:{
      'tree-item__label--selected': _vm.isActiveItem,
      'tree-item__label--inactive': _vm.isInactive()
    }},[(_vm.isWarning())?_c('icon',{staticClass:"tree-item__icon",attrs:{"data":require("@tree/warn_collored.svg")}}):(_vm.isChecked())?_c('icon',{staticClass:"tree-item__icon",attrs:{"data":require("@tree/approve_green.svg")}}):_vm._e(),_vm._l((_vm.itemIcons),function(imgItem,imgIndex){return _c('icon',{key:imgItem.image + imgIndex,staticClass:"tree-item__icon",class:{
        'tree-item__icon--grey': imgItem.class === 'grey-icon',
        'tree-item__icon--active': _vm.isActiveItem,
        'fill-off': imgItem.new
      },attrs:{"data":imgItem.image}})}),_vm._v(" "+_vm._s(_vm.item.text)+" "),(_vm.isStockShown() && _vm.checkIsStockShown(_vm.item.overstock))?_c('div',{staticClass:"stock-amount__item stock-amount__item--overstock"},[_vm._v(" "+_vm._s(_vm.item.overstock)+" ")]):_vm._e(),(_vm.isStockShown() && _vm.checkIsStockShown(_vm.item.stockout))?_c('div',{staticClass:"stock-amount__item stock-amount__item--stockout"},[_vm._v(" "+_vm._s(_vm.item.stockout)+" ")]):_vm._e()],2)],2)}
var staticRenderFns = []

export { render, staticRenderFns }