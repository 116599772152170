<template>
  <ToolbarWrapper class="demand-toolbar">
    <template #search>
      <Search
        v-model="treeSearchModel"
        :results="treeSearchResults"
        :load-callback="loadSearchResults"
        class="tree-search"
        with-results
        results-lazy-load
        data-test-id="tree-search-txt"
        @submit-search="submitSearch"
        @select-item="handleSelectResultItem"
        @check-scroll="updateResultScroll"
      >
        <template #result-item="{ item: nodes }">
          <template v-if="nodes.length">
            <template v-for="(node, nodeIndex) in nodes">
              <div
                v-if="isSearchItemPathVisible(nodes, nodeIndex)"
                :key="node.id"
                class="tree-search__item"
              >
                <div
                  v-tooltip="getSearchItemTooltip(nodes, nodeIndex)"
                  class="tree-search__item-text"
                >
                  {{ getSearchItemText(nodes, nodeIndex) }}
                </div>
                <icon
                  v-if="nodeIndex < nodes.length - 1"
                  data="@icon/arrow-down.svg"
                  class="size-16 rotate--90 tree-search__item-divider"
                />
              </div>
            </template>
          </template>
          <div
            v-else
            class="tree-search__item--no-data"
          >
            {{ $t('Web.NoData.SearchTitle') }}
          </div>
        </template>
      </Search>
    </template>
    <ToolbarButton
      :label="$t('Main.Ui.acApprove')"
      :disabled="isApproveForecastDisabled"
      data-toolbar-item
      @click.native="$emit('toggle-approve')"
    >
      <icon
        data="@icon/apply.svg"
        class="size-16"
      />
    </ToolbarButton>
    <ToolbarButton
      :label="$t('Main.Ui.acNeedsAttention')"
      :disabled="isEditNodeDisabled"
      data-toolbar-item
      @click.native="$emit('needs-attention')"
    >
      <icon data="@icon/warn_grey.svg" />
    </ToolbarButton>
    <ToolbarButton
      :label="$t('Main.Ui.acMarkChecked')"
      :disabled="isEditNodeDisabled"
      data-toolbar-item
      @click.native="$emit('toggle-checked')"
    >
      <icon
        data="@icon/approve_grey.svg"
        class="size-16"
      />
    </ToolbarButton>
    <ToolbarButton
      :label="$t('Main.Ui.acToolbarNote')"
      :disabled="isAddNoteDisabled"
      data-toolbar-item
      @click.native="$emit('add-note')"
    >
      <icon
        data="@icons/note.svg"
        class="fill-off size-16"
      />
    </ToolbarButton>
    <ToolbarButton
      :label="$t('Web.CustomFields.CustomFields')"
      :disabled="isForecastVersionsDisabled"
      data-toolbar-item
      @click.native="openForecastVersions"
    >
      <icon
        data="@icon/forecast.svg"
        class="size-16"
      />
    </ToolbarButton>
    <ColVisibilityMenu
      v-if="availableTableRows"
      :items="availableTableRows"
      :title="$t('Main.CustomizeRowsMenu')"
      @change-col-visibility="updateVisibleRows"
    />
    <template #additional>
      <v-breadcrumbs
        v-if="isBreadcrumbsVisible"
        class="sl-breadcrumbs demand-breadcrumbs"
        :items="treeBreadcrumbs"
        divider=">"
      >
        <template #item="{ item }">
          <v-breadcrumbs-item
            v-tooltip="item.text"
            class="color--link"
            @click="() => handleBreadcrumbClick(item.id)"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </template>
  </ToolbarWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import ToolbarWrapper from '@/components/Toolbar/ToolbarWrapper.vue';
import ToolbarButton from '@/components/Toolbar/ToolbarButton.vue';
import ColVisibilityMenu from '@/components/Toolbar/ColVisibilityMenu.vue';
import Search from '@/components/Toolbar/Search.vue';
import modalsId from '@/config/shared/modalsId.config';
import { routeNames } from '@/config/router/router.config';

export default {
  name: 'Toolbar',
  components: {
    ToolbarWrapper,
    ToolbarButton,
    Search,
    ColVisibilityMenu
  },
  mixins: [modal, access],
  data() {
    return {
      modalsId,
      visiblePathItemCount: 4,
      minTooltipLength: 20,
      isResultsScrolling: false,
      versionsCopy: []
    };
  },
  computed: {
    ...mapState({
      treeState: state => state.demand.tree,
      treeSearchResults: state => state.demand.tree.tree_search_results,
      isAddNoteDisabled: state => !state.note.canEditNote
    }),
    ...mapGetters({
      treeBreadcrumbs: 'demand/tree/treeBreadcrumbs',
      availableTableRows: 'demand/table/availableRows',
      nodeFlagByShift: 'demand/table/nodeFlagByShift'
    }),
    isEditNodeDisabled() {
      return !this.$sl_tabEditable || !this.$sl_hasAccess(this.$sl_features.demandNode);
    },
    isForecastVersionsDisabled() {
      return this.isEditNodeDisabled || !this.$sl_hasAccess(this.$sl_features.manageForecastVersions);
    },
    isApproveForecastDisabled() {
      return this.isEditNodeDisabled || !this.$sl_hasAccess(this.$sl_features.approveForecast);
    },
    isBreadcrumbsVisible() {
      return this.treeBreadcrumbs.length > 1;
    },
    treeSearchModel: {
      get() {
        return this.treeState.tree_search;
      },
      set(val) {
        this.setTreeSearch(val);
      }
    }
  },
  methods: {
    ...mapActions({
      setTreeSearch: 'demand/tree/setSearchValue',
      submitSearch: 'demand/tree/searchTreeNodes',
      loadSearchResults: 'demand/tree/loadFoundNodes',
      fetchFoundNodeTree: 'demand/tree/fetchFoundNodeTree',
      setActiveNodeByNodeId: 'demand/tree/setActiveNodeByNodeId',
      updateVisibleRows: 'demand/table/updateVisibleRows'
    }),
    isSearchItemPathVisible(nodes, index) {
      if (nodes.length === 1 && !index) {
        return true;
      }

      if (!index) {
        return false;
      }

      // show first visible (after all items) and last 3 items
      return index === 1 || index >= nodes.length - this.visiblePathItemCount;
    },
    isHiddenSearchItem(nodes, index) {
      // if more than first visible and less than last 3
      return index > 1 && index <= nodes.length - this.visiblePathItemCount;
    },
    getSearchItemTooltip(nodes, index) {
      const text = nodes[index].text;
      const hiddenItems = nodes.reduce((acc, pathItem, pathIndex) => {
        if (this.isHiddenSearchItem(nodes, pathIndex)) {
          acc.push(pathItem.text);
        }

        return acc;
      }, []);

      if (this.isHiddenSearchItem(nodes, index)) {
        return {
          content: hiddenItems.join(' > '),
          disabled: this.isResultsScrolling
        };
      }

      return {
        content: text,
        disabled: text.length < this.minTooltipLength || this.isResultsScrolling
      };
    },
    getSearchItemText(nodes, index) {
      return this.isHiddenSearchItem(nodes, index)
        ? '...'
        : nodes[index].text;
    },
    updateResultScroll(val) {
      this.isResultsScrolling = val;
    },
    handleBreadcrumbClick(id) {
      const nodeIndex = this.treeBreadcrumbs.findIndex(item => item.id === id);

      if (nodeIndex !== -1 && nodeIndex !== this.treeBreadcrumbs.length - 1) {
        this.setActiveNodeByNodeId(id);
      }
    },
    openForecastVersions() {
      this.$router.push({
        name: routeNames.FORECAST_VERSIONS
      });
    },
    handleSelectResultItem(nodes) {
      if (!nodes.length) {
        return;
      }

      const last = nodes.at(-1);

      this.fetchFoundNodeTree(last.id);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/style/components/demand/toolbar.scss';
</style>
