<template>
  <div v-if="availableInventory">
    <div v-if="invParamCell">
      <div class="dem-settings__header-row">
        <div class="dem-settings__header">
          {{ $t('Main.Value') }}
        </div>
      </div>
      <div
        v-for="(item, index) in invParamCell"
        :key="index"
        class="dem-settings__row-wrap"
      >
        <SettingsRow
          :title="item.name"
          :value="item.val"
          :editable="false"
          :background="item.background"
          :foreground="item.foreground"
        />
      </div>
    </div>
    <DemandSettingsSkeleton v-if="loading" />
  </div>
  <div
    v-else
    class="dem-settings__message"
  >
    {{ message }}
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import SettingsRow from '@/components/Demand/DemandSettings/SettingsRow.vue';
import DemandSettingsSkeleton from '@/components/Skeleton/DemandSettingsSkeleton.vue';

export default {
  name: 'InventoryTab',
  components: {
    SettingsRow,
    DemandSettingsSkeleton
  },
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    },
    availableInventory: Boolean
  },
  data() {
    return {
      loading: true
    };
  },
  computed: {
    ...mapState({
      invParamCell: state => state.invParam.invParamObj
    })
  },
  async beforeMount() {
    try {
      this.loading = true;

      await this.fetchInv();
    } finally {
      this.loading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchInv: 'invParam/fetchInv'
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/tabSettings.scss";
</style>
