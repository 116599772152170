var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"cell",on:{"contextmenu":function($event){$event.preventDefault();}}},[_vm._v(" "+_vm._s(_vm.tableCol.date)+" ")]),(_vm.isRowExists(_vm.colKeys.actual.headerClass))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getNoteTooltipValue(_vm.tableCol.actual)),expression:"getNoteTooltipValue(tableCol.actual)"}],staticClass:"cell",class:{
      'has-note': _vm.isHasNote(_vm.tableCol.actual)
    },attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.actual.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.actual.xmlKey); })($event)}}},[(_vm.tableCol.actual)?_c('TableInput',{class:_vm.getCellClasses(_vm.colKeys.actual.xmlKey),attrs:{"disabled":_vm.isActualDisabled,"text-value":_vm.formatNumber(_vm.actualSalesValue),"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.colKeys.actual.xmlKey, value); }},model:{value:(_vm.actualSalesValue),callback:function ($$v) {_vm.actualSalesValue=$$v},expression:"actualSalesValue"}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.lowerEchelonDemand.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.lowerEchelonDemand.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.lowerEchelonDemand.xmlKey); }}},[(_vm.tableCol.lowerEchelonDemand)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.lowerEchelonDemand.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.lowerEchelonDemand.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.materialConsumption.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.materialConsumption.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.materialConsumption.xmlKey); }}},[(_vm.tableCol.materialConsumption)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.materialConsumption.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.materialConsumption.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.statisticalForecast.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.statisticalForecast.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.statisticalForecast.xmlKey); }}},[(_vm.isDisplayStatisticalForecast)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.statisticalForecast.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.statisticalForecast.val))+" ")]):_vm._e()]):_vm._e(),_vm._l((_vm.overrideCount),function(ov,i){return [(_vm.isForecastExist(i))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getNoteTooltipValue(_vm.tableCol.customRowOverrides[i])),expression:"getNoteTooltipValue(tableCol.customRowOverrides[i])"}],key:ov,staticClass:"cell",class:{
        'color--over': _vm.tableCol.customRowOverrides.editable,
        'has-note': _vm.isHasNote(_vm.tableCol.customRowOverrides[i])
      },attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
        event: $event,
        cellKey: _vm.colKeys.customRowOverrides.xmlKey,
        index: i
      })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.customRowOverrides.xmlKey, i); })($event)}}},[(_vm.tableCol.customRowOverrides[i] !== undefined)?_c('TableInput',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getFormulaTooltip(i)),expression:"getFormulaTooltip(i)"}],class:_vm.getForecastOverrideClasses(i),attrs:{"value":_vm.getForecastOverrideValue(i),"text-value":_vm.getForecastOverrideTextValue(i),"disabled":_vm.isForecastDisabled(_vm.tableCol.customRowOverrides[i]),"max-length":_vm.formulaMaxLength,"invalid":_vm.getFormulaError(i),"type":"formula","focus-visible":""},on:{"input":function (value) { return _vm.updateForecastOverridesCell(value, i); },"submit":function (value) { return _vm.submitPredictionOverride(value, i); }}}):_vm._e()],1):_vm._e()]}),(_vm.isRowExists(_vm.colKeys.UpperConfLimit.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.UpperConfLimit.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.UpperConfLimit.xmlKey); }}},[(_vm.tableCol.upperConfLimit)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.UpperConfLimit.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.upperConfLimit.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.LowerConfLimit.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.LowerConfLimit.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.LowerConfLimit.xmlKey); }}},[(_vm.tableCol.lowerConfLimit)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.LowerConfLimit.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.lowerConfLimit.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.AvgPrice.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.AvgPrice.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.AvgPrice.xmlKey); })($event)}}},[(_vm.tableCol.AvgPrice)?_c('TableInput',{class:_vm.getCellClasses(_vm.colKeys.AvgPrice.xmlKey),attrs:{"disabled":_vm.isAvgPriceDisabled,"input-regexp":_vm.regExp.floatInput,"submit-regexp":_vm.regExp.floatSubmit,"text-value":_vm.formatNumber(_vm.avgPriceValue),"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.colKeys.AvgPrice.xmlKey, value); }},model:{value:(_vm.avgPriceValue),callback:function ($$v) {_vm.avgPriceValue=$$v},expression:"avgPriceValue"}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.Revenue.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.revenueType.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.revenueType.xmlKey); })($event)}}},[_c('TableInput',{class:_vm.getCellClasses(_vm.revenueType.xmlKey),attrs:{"disabled":_vm.revenueType.disabled,"input-regexp":_vm.regExp.floatInput,"submit-regexp":_vm.regExp.floatSubmit,"text-value":_vm.formatNumber(_vm.revenueType.value),"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.revenueType.xmlKey, value); }},model:{value:(_vm.revenueType.value),callback:function ($$v) {_vm.$set(_vm.revenueType, "value", $$v)},expression:"revenueType.value"}})],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.profit.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.profit.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.profit.xmlKey); })($event)}}},[(_vm.tableCol.profit)?_c('TableInput',{class:_vm.getCellClasses(_vm.colKeys.profit.xmlKey),attrs:{"disabled":_vm.isDisabled || !_vm.tableCol.profit.editable,"input-regexp":_vm.regExp.floatInput,"submit-regexp":_vm.regExp.floatSubmit,"text-value":_vm.formatNumber(_vm.profitValue),"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.colKeys.profit.xmlKey, value); }},model:{value:(_vm.profitValue),callback:function ($$v) {_vm.profitValue=$$v},expression:"profitValue"}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.OnHand.headerClass))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.getNoteTooltipValue(_vm.tableCol.OnHand)),expression:"getNoteTooltipValue(tableCol.OnHand)"}],staticClass:"cell",class:{
      'has-note': _vm.isHasNote(_vm.tableCol.OnHand)
    },attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.OnHand.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.OnHand.xmlKey); })($event)}}},[(_vm.tableCol.OnHand)?_c('TableInput',{class:_vm.getCellClasses(_vm.colKeys.OnHand.xmlKey),attrs:{"disabled":_vm.isOnHandDisabled,"input-regexp":_vm.regExp.negativeFloatInput,"submit-regexp":_vm.regExp.floatSubmit,"text-value":_vm.formatNumber(_vm.onHandValue),"max-length":9,"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.colKeys.OnHand.xmlKey, value); }},model:{value:(_vm.onHandValue),callback:function ($$v) {_vm.onHandValue=$$v},expression:"onHandValue"}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.LostDays.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.LostDays.xmlKey
    })},"!focus":function($event){return (function () { return _vm.handleCellFocus(_vm.colKeys.LostDays.xmlKey); })($event)}}},[(_vm.tableCol.LostDays)?_c('TableInput',{class:_vm.getCellClasses(_vm.colKeys.LostDays.xmlKey),attrs:{"disabled":_vm.isDisabled || !_vm.tableCol.LostDays.editable,"input-regexp":_vm.regExp.floatInput,"submit-regexp":_vm.regExp.floatSubmit,"max-length":4,"focus-visible":""},on:{"input":_vm.startInput,"submit":function (value) { return _vm.submitValue(_vm.colKeys.LostDays.xmlKey, value); }},model:{value:(_vm.lostDaysValue),callback:function ($$v) {_vm.lostDaysValue=$$v},expression:"lostDaysValue"}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.ServiceLevel.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.ServiceLevel.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.ServiceLevel.xmlKey); }}},[(_vm.tableCol.ServiceLevel)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.ServiceLevel.xmlKey)},[_vm._v(" "+_vm._s(_vm.tableCol.ServiceLevel.val)+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.remainder.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.remainder.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.remainder.xmlKey); }}},[(_vm.tableCol.remainder)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.remainder.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.remainder.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.projectedDaysOfSupply.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.projectedDaysOfSupply.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.projectedDaysOfSupply.xmlKey); }}},[(_vm.tableCol.projectedDaysOfSupply)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.projectedDaysOfSupply.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.projectedDaysOfSupply.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.toOrder.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.toOrder.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.toOrder.xmlKey); }}},[(_vm.tableCol.toOrder)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.toOrder.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.toOrder.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.allowOrder.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.allowOrder.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.allowOrder.xmlKey); }}},[(_vm.tableCol.allowOrder)?_c('v-checkbox',{staticClass:"sl-checkbox",attrs:{"input-value":_vm.allowOrderValue,"disabled":_vm.IsDisabledOrderingAvailability},on:{"change":_vm.handleOrderingAvailabilityChange}}):_vm._e()],1):_vm._e(),(_vm.isRowExists(_vm.colKeys.onorder.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.onorder.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.onorder.xmlKey); }}},[(_vm.tableCol.onorder)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.onorder.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.onorder.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.backorder.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.backorder.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.backorder.xmlKey); }}},[(_vm.tableCol.backorder)?_c('div',{staticClass:"cell__text",class:_vm.getCellClasses(_vm.colKeys.backorder.xmlKey)},[_vm._v(" "+_vm._s(_vm.formatNumber(_vm.tableCol.backorder.val))+" ")]):_vm._e()]):_vm._e(),(_vm.isRowExists(_vm.colKeys.customOutlier.headerClass))?_c('div',{staticClass:"cell",attrs:{"tabindex":"-1"},on:{"contextmenu":function($event){$event.preventDefault();return _vm.handleContextMenuClick({
      event: $event,
      cellKey: _vm.colKeys.customOutlier.xmlKey
    })},"focus":function () { return _vm.handleCellFocus(_vm.colKeys.customOutlier.xmlKey); }}},[(_vm.tableCol.customOutlier)?_c('div',{staticClass:"cell__checkbox"},[_c('v-checkbox',{key:_vm.isOutlierChecked + _vm.isOutlierMixed,staticClass:"sl-checkbox",attrs:{"disabled":_vm.isDisabled,"input-value":_vm.isOutlierChecked,"indeterminate":_vm.isOutlierMixed,"readonly":_vm.isOutlierMixed},on:{"click":_vm.handleOutlierClick,"change":_vm.handleOutlierChange}}),(_vm.isOutlierOverridden)?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('Main.DemandTable.ToolTips.ClearOverrides')),expression:"$t('Main.DemandTable.ToolTips.ClearOverrides')"}],staticClass:"cell__checkbox-arrow",on:{"click":function($event){return _vm.popUpClear()}}},[_c('icon',{attrs:{"data":require("@icon/revert_blue.svg")}})],1):_vm._e()],1):_vm._e()]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }