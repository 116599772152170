<template>
  <div class="data-wrapper">
    <transition name="fade">
      <div
        v-if="dataLoaded"
        class="data"
      >
        <vue-resizable
          class="vertical-resizable"
          :active="verticalResizeConfig.active"
          :disable-attributes="verticalResizeConfig.disabledAttrs"
          :height="verticalResizeConfig.height"
          :min-height="verticalResizeConfig.minHeight"
          @resize:end="handleTableResize"
        >
          <Table
            class="table-info table-info__content"
            :server-side-col-sizes="serverSideColSizes"
            @table-ready="displayTable"
            @table-data-change="(data) => $emit('table-data-change', data)"
            @on-input-change="(data) => $emit('on-input-change', data)"
          />
        </vue-resizable>
        <div class="data__footer">
          <div class="chart">
            <div class="chart__title">
              <span>
                {{ chartTitle }}
              </span>
            </div>
            <v-tabs
              v-if="chartTabs.length"
              :value="tabModel"
              class="sl-tabs chart__tabs"
            >
              <v-tab
                v-for="item in chartTabs"
                :key="item.value"
                @click="setChartTab(item.value)"
              >
                {{ item.label }}
              </v-tab>
            </v-tabs>
            <div
              class="chart__wrapper"
              :class="{ 'chart__wrapper--with-tabs': chartTabs.length }"
            >
              <ChartContextMenu ref="chart-context-menu" />
              <transition name="fade">
                <Chart
                  v-if="!isChartLoading"
                  :key="chartKey"
                  :chart-data="chartData"
                  :options="chartOptions"
                  class="chart__container"
                />
              </transition>
              <SlOverlay :show="isChartLoading" />
            </div>
          </div>
          <vue-resizable
            :active="horizontalResizeConfig.active"
            :disable-attributes="horizontalResizeConfig.disabledAttrs"
            :width="horizontalResizeConfig.width"
            :min-width="horizontalResizeConfig.minWidth"
            class="horizontal-resizable demand-settings__wrapper"
            @resize:end="handleSettingsResize"
          >
            <DemandSettings
              @form-focus="$emit('form-focus')"
            />
          </vue-resizable>
        </div>
        <SlContextMenu
          ref="slTableContextMenu"
          element-id="sl-table-context-menu"
          :options="contextMenuOptions"
          @option-clicked="handleOptionClick"
        >
          <template #[contextMenuKeys.CUT]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.COPY]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.PASTE]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.DELETE]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.ADD_NOTE]="{ handler, name }">
            <OptionItem
              :handler="handler"
              :disabled="!getActionFgs(demandTableContextMenuFgs[contextMenuKeys.ADD_NOTE], fgsActionTypes.ENABLED)"
            >
              {{ noteLabel || name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.APPLY_CELL_TO_FINAL_FORECAST]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST]="{ handler, name }">
            <OptionItem
              :handler="handler"
              :disabled="!getActionFgs(demandTableContextMenuFgs[contextMenuKeys.APPLY_ROW_TO_FINAL_FORECAST], fgsActionTypes.ENABLED)"
            >
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST]="{ handler, name }">
            <OptionItem
              :handler="handler"
              :disabled="!getActionFgs(demandTableContextMenuFgs[contextMenuKeys.COPY_CELL_FROM_STATISTICAL_FORECAST], fgsActionTypes.ENABLED)"
            >
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST]="{ handler, name }">
            <OptionItem
              :handler="handler"
              :disabled="!getActionFgs(demandTableContextMenuFgs[contextMenuKeys.COPY_ROW_FROM_STATISTICAL_FORECAST], fgsActionTypes.ENABLED)"
            >
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.PROPAGATE_VALUE]="{ handler, name }">
            <OptionItem
              :handler="handler"
              :disabled="!getActionFgs(demandTableContextMenuFgs[contextMenuKeys.PROPAGATE_VALUE], fgsActionTypes.ENABLED)"
            >
              {{ name }}
            </OptionItem>
          </template>
          <template #[contextMenuKeys.EXPORT_NODE_TO_XLSX]="{ handler, name }">
            <OptionItem :handler="handler">
              {{ name }}
            </OptionItem>
          </template>
        </SlContextMenu>
      </div>
    </transition>
    <SlOverlay :show="!dataLoaded || !isTableReady" />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import VueResizable from 'vue-resizable';
import logger from '@/api/logger';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import { fileSaver } from '@/mixins/webAPI';
import Table from './Table/Table.vue';
import Chart from './Chart.vue';
import DemandSettings from './DemandSettings/DemandSettings.vue';
import SlContextMenu from '@/components/Shared/SlContextMenu/Index.vue';
import OptionItem from '@/components/Shared/SlContextMenu/OptionItem.vue';
import SlOverlay from '@/components/UIKit/SlOverlay';
import ChartContextMenu from '@/components/Demand/ChartContextMenu.vue';
import {
  chartOptions,
  chartTabKeys,
  chartTabs
} from '@/config/demand/chart/chart.config';
import { uiTabByRoute, uiSettingsKeys } from '@/config/users/uiSettings.config';
import {
  demandTableContextMenuOption,
  demandTableContextMenuActionsMap,
  contextMenuKeys,
  fgsActionTypes,
  contextMenuAdjustment
} from '@/config/shared/contextMenu.config';
import { colKeys } from '@/config/demand/table.config';
import { demandColsFlags } from '@/config/shared/fgs.config';
import modalsId from '@/config/shared/modalsId.config';
import { noteTypes } from '@/config/shared/note.config';
import { demandTableContextMenuFgs } from '@/config/shared/fgs.config';
import { readClipboardText, copyToClipboard } from '@/helpers/utils/clipboard';

export default {
  name: 'DemandData',
  components: {
    ChartContextMenu,
    SlOverlay,
    VueResizable,
    SlContextMenu,
    OptionItem,
    Table,
    Chart,
    DemandSettings
  },
  mixins: [modal, access, fileSaver],
  data() {
    return {
      modalsId,
      demandTableContextMenuFgs,
      contextMenuKeys,
      fgsActionTypes,
      isTableReady: false,
      selectedCell: null
    };
  },
  provide() {
    return {
      contextMenuHandler: this.handleContextMenu,
      focusCellHandler: this.focusCellHandler
    };
  },
  computed: {
    ...mapState({
      table: (state) => Boolean(state.demand.table.table),
      chartTitle: (state) => state.demand.chart.chartTitle,
      chartType: (state) => state.demand.chart.chartType,
      serverSideColSizes: (state) => state.demand.table.colSizes,
      isChartLoading: (state) => state.demand.chart.isLoading,
      ui_settings: (state) => state.user.ui_settings
    }),
    ...mapGetters({
      chartData: 'demand/chart/filledChartData',
      chartDates: 'demand/chart/localeChartDates',
      hasStatisticalForecastRow: 'demand/table/hasStatisticalForecastRow',
      hasFinalForecastRow: 'demand/table/hasFinalForecastRow',
      tablePoints: 'demand/table/tablePoints',
      forecastVersionNames: 'demand/table/forecastVersionNames'
    }),
    demandUiSettings() {
      return this.ui_settings[uiTabByRoute[this.$sl_routeName]];
    },
    verticalResizeConfig() {
      return {
        active: ['b'],
        disabledAttrs: ['l', 't', 'w'],
        height: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC][uiSettingsKeys.DEMAND_TABLE_HEIGHT] || '50%',
        minHeight: 1
      };
    },
    horizontalResizeConfig() {
      return {
        active: ['l'],
        disabledAttrs: ['l', 't', 'h'],
        width: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC][uiSettingsKeys.DEMAND_SETTINGS_WIDTH] || 'auto',
        minWidth: 300
      };
    },
    chartTabs() {
      if (!this.$sl_hasAccess(this.$sl_features.salesData)) {
        return [];
      }

      return [
        {
          label: this.$t('Main.ChartQty'),
          value: chartTabKeys.QTY
        },
        {
          label: this.$t('Main.ChartValue'),
          value: chartTabKeys.REVENUE
        }
      ];
    },
    tabModel: {
      get() {
        return chartTabs.indexOf(this.chartType);
      },
      set(value) {
        this.setChartType(value);
      }
    },
    dataLoaded() {
      return Boolean(this.table && this.chartData && this.serverSideColSizes);
    },
    contextMenuOptions() {
      const options = demandTableContextMenuOption(this).filter(option => {
        const availableVisually = option.type || option.alwaysVisible;
        const availableFromServer = this.getActionFgs(demandTableContextMenuFgs[option.key], fgsActionTypes.VISIBLE);

        return availableVisually || availableFromServer;
      }, []);

      const dividerIndex = options.findIndex((option) => option.type);

      if (dividerIndex === 0) {
        options.shift();
      }

      return options;
    },
    currentCell() {
      if (!this.selectedCell) {
        return null;
      }

      const { index, position, cellKey } = this.selectedCell;

      if (index !== null) {
        return this.selectedCell;
      }

      return this.tablePoints[position][cellKey];
    },
    colFgs() {
      if (!this.selectedCell) {
        return null;
      }

      const { position } = this.selectedCell;

      return this.tablePoints[position].fgs;
    },
    noteLabel() {
      if (this.currentCell?._note || this.currentCell?.note) {
        return this.$t('Main.EditNote');
      }

      return this.$t('Main.AddNote');
    },
    isAddNoteDisabled() {
      if (!this.selectedCell) {
        return true;
      }

      if (!this.$sl_tabEditable) {
        return true;
      }

      const { cellKey } = this.selectedCell;

      if (cellKey === colKeys.customRowOverrides.xmlKey) {
        return !(demandColsFlags.ALLOW_OVERRIDE_NOTE & this.colFgs);
      }

      if (cellKey === colKeys.actual.xmlKey) {
        return !(demandColsFlags.ALLOW_ACTUAL_DATA_NOTE & this.colFgs);
      }

      if (cellKey === colKeys.OnHand.xmlKey) {
        return !(demandColsFlags.ALLOW_ONHAND_NOTE & this.colFgs);
      }

      return true;
    },
    chartOptions() {
      return chartOptions({
        ctx: this,
        legendClickCallback: this.legendClickHandler,
        xLabels: this.chartDates,
        tab: this.chartType
      });
    },
    chartKey() {
      return `${this.chartType}-${this.chartData.labels.length}`;
    }
  },
  watch: {
    chartTabs() {
      this.tabModel = chartTabKeys.QTY;
    }
  },
  methods: {
    ...mapActions('demand/table', [
      'applyCellFinalForecast',
      'applyRowFinalForecast',
      'copyCellFromStatisticalForecast',
      'copyRowFromStatisticalForecast',
      'propagateValue'
    ]),
    ...mapActions({
      setCurrentNote: 'note/setCurrentNote',
      setCurrentCellInfo: 'note/setCurrentCellInfo',
      updateCellNote: 'note/updateCellNote',
      exportNodeToXlsx: 'demand/tree/exportNodeToXlsx',
      setChartType: 'demand/chart/setChartType',
      changeUiSettings: 'user/changeUiSettings',
      updateCurveVisibility: 'demand/chart/updateCurveVisibility'
    }),
    handleTableResize({ height }) {
      this.changeUiSettings({
        key: uiSettingsKeys.SPECIFIC,
        value: {
          [uiSettingsKeys.DEMAND_TABLE_HEIGHT]: height
        }
      });
    },
    handleSettingsResize({ width }) {
      this.changeUiSettings({
        key: uiSettingsKeys.SPECIFIC,
        value: {
          [uiSettingsKeys.DEMAND_SETTINGS_WIDTH]: width
        }
      });
    },
    getCellActionsByType(cell) {
      return {
        [fgsActionTypes.VISIBLE]: cell.visibleAcs,
        [fgsActionTypes.ENABLED]: cell.enabledAcs
      };
    },
    getActionFgs(action, type) {
      const cell = this.selectedCell?.cell;

      if (!cell) {
        return false;
      }

      const actionsByType = this.getCellActionsByType(cell);

      return !!(actionsByType[type] & action);
    },
    displayTable() {
      this.isTableReady = true;
    },
    setChartTab(value) {
      this.tabModel = value;
    },
    handleExportNodeToXlsx() {
      this.saveFile(this.exportNodeToXlsx.bind(this, false));
    },
    legendClickHandler(_, { datasetIndex }, { chart }) {
      const itemMeta = chart.getDatasetMeta(datasetIndex);
      const labelKey = itemMeta._dataset.labelKey;

      itemMeta.hidden = itemMeta.hidden === null
        ? !chart.data.datasets[datasetIndex].hidden
        : null;

      this.updateCurveVisibility({
        curve: labelKey,
        visibility: !itemMeta.visible
      });

      chart.update();
    },
    handleContextMenu(event, cellInfo) {
      this.$refs.slTableContextMenu.showMenu(
        event,
        cellInfo,
        contextMenuAdjustment.pageX,
        null,
        '.table__scroller'
      );
    },
    focusCellHandler(cellInfo) {
      this.selectedCell = cellInfo;

      this.setCurrentNote({
        note: cellInfo.note,
        canEdit: !this.isAddNoteDisabled,
        type: noteTypes.DEMAND_CELL
      });

      this.setCurrentCellInfo({
        position: cellInfo.position,
        index: cellInfo.index,
        name: colKeys[cellInfo.cellKey]?.requestKey || ''
      });
    },
    handleOptionClick(event) {
      if (event?.option?.key) {
        const action = demandTableContextMenuActionsMap[event.option.key];

        if (this[action]) {
          this[action](event.item);
        }
      }
    },
    copyCell({ value }) {
      copyToClipboard(value);
    },
    async pasteCell(cellInfo) {
      if (!navigator.clipboard) {
        return this.$notify({
          type: 'error',
          text: this.$t('Web.Context.ErrorHttp')
        });
      }

      try {
        const val = await readClipboardText();

        if (!val) {
          return;
        }

        const payload = {
          p: cellInfo.position,
          val,
          isOver: cellInfo.index
        };

        this.$store.dispatch('demand/table/updateOverridePrediction', payload);
      } catch (e) {
        if (e.name === 'NotAllowedError') {
          this.$notify({
            type: 'error',
            text: this.$t('Web.Context.ErrorDenied')
          });
        }

        logger.formatAndWriteError({
          e,
          from: 'pasteCell'
        });
      }
    },
    cutCell(cellInfo) {
      this.copyCell(cellInfo);
      this.deleteCell(cellInfo);
    },
    deleteCell(cellInfo) {
      const payload = {
        p: cellInfo.position,
        val: '',
        isOver: cellInfo.index
      };

      this.$store.dispatch('demand/table/updateOverridePrediction', payload);
    },
    handleAddNote() {
      this.showModal(modalsId.ADD_NOTE);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/index.scss";
@import "@/style/components/demand/table/tableInfo.scss";
@import "@/style/utils/variable";
</style>
