<script>
import { Line, mixins } from 'vue-chartjs';
import 'chartjs-adapter-moment';
import { mouseLine } from '@/plugins/chartjs/demand/mouseLine.plugin';
import { contextMenuPlugin } from '@/plugins/chartjs/demand/contextMenu.plugin';

import { Chart as ChartJs } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';

ChartJs.register(zoomPlugin);

export default {
  name: 'Chart',
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    options: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    options() {
      this.$data._chart.update();
    }
  },
  mounted() {
    this.addPlugin(mouseLine);
    this.addPlugin(contextMenuPlugin);
    this.renderChart(this.chartData, this.options);

    this.$nextTick(() => {
      this.$data._chart.update();
    });
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/chart/chart";
</style>