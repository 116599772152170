<template>
  <div class="dem-settings__forecast">
    <div class="dem-settings__forecast-title">
      {{ name }}
    </div>
    <div
      class="dem-settings__select-wrapper"
      :data-test-id="`forecasting-row-${rowKey}`"
    >
      <v-select
        v-if="isSelect"
        :items="choice"
        :value="selectValue"
        :disabled="isDisabled"
        :menu-props="{
          contentClass: 'sl-select__menu',
          closeOnContentClick: true,
          auto: true
        }"
        class="sl-select sl-select--grey forecasting-select"
        dense
        solo
        @focus="handleElementFocus"
        @blur="$emit('focus')"
      >
        <template #selection="{ item }">
          <div class="v-select__selection dem-settings__select-selection">
            <icon
              v-if="item.icon"
              :data="require(`@tree/${item.icon}.svg`)"
              class="size-12"
            />
            <span>{{ item.text }}</span>
          </div>
        </template>
        <template #item="{ item, attrs }">
          <v-list-item
            v-bind="attrs"
            class="dem-settings__select-item"
            @click="() => handleChange(item.value)"
          >
            <v-list-item-content>
              <icon
                v-if="item.icon"
                :data="require(`@tree/${item.icon}.svg`)"
                class="size-12"
              />
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-select>
      <div
        v-if="button"
        class="dem-settings__select-button"
        :class="{ 'dem-settings__select-button--disabled': isDisabled }"
        @click="button.callback"
      >
        <icon
          :data="require(`@icon/${button.icon}`)"
          class="color-grey-dark size-14"
        />
      </div>
    </div>
    <v-combobox
      v-if="isCombobox"
      ref="forecastingCombobox"
      v-model="vModel"
      v-tooltip="comboboxTooltipValue"
      :items="choice"
      :disabled="isDisabled"
      :menu-props="{
        contentClass: 'sl-select__menu',
        auto: true
      }"
      class="sl-select sl-select--white sl-combobox forecasting-select"
      :class="{ 'background--red-': isRedCombobox }"
      dense
      solo
      no-filter
      @update:search-input="handleComboboxSearch"
      @change="changeCombobox"
      @focus="handleElementFocus"
    >
      <template #no-data>
        <div class="sl-select__no-data">
          {{ $t('Web.Forecasting.NoDataUseModelFrom') }}
        </div>
      </template>
      <template
        v-if="appendIcon.icon"
        #append-outer
      >
        <i v-tooltip="appendIcon.title(vModel)">
          <icon
            :data="require(`@tree/${appendIcon.icon}.svg`)"
            class="size-12"
          />
        </i>
      </template>
    </v-combobox>
    <v-combobox
      v-if="isApprovalCombobox"
      v-model="vModel"
      :items="choice"
      :disabled="isDisabled"
      :menu-props="{
        contentClass: 'sl-select__menu',
        auto: true
      }"
      class="sl-select sl-select--white sl-combobox forecasting-select"
      dense
      solo
      no-filter
      @change="changeApprovalCombobox"
      @focus="handleElementFocus"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { access } from '@/mixins/access';
import { modal } from '@/mixins/modal';
import { forecastingKeys } from '@/config/demand/demandSettings.config';
import modalsId from '@/config/shared/modalsId.config';
import { customDebounce } from '@/helpers/shared/listeners';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'ForecastingRow',
  mixins: [access, modal],
  props: {
    name: {
      type: String,
      default: ''
    },
    state: {
      type: [String, Number, Object],
      default: ''
    },
    rowKey: {
      type: String,
      default: ''
    },
    choice: {
      type: [Object, Array],
      required: true
    },
    appendIcon: {
      type: Object,
      default: () => {}
    },
    min: {
      type: Number,
      default: -1
    },
    max: {
      type: Number,
      default: -1
    },
    button: {
      type: [Object, null],
      default: null
    }
  },
  data() {
    return {
      forecastingKeys,
      selectValue: this.state,
      vModel: this.state,
      comboboxKeys: [forecastingKeys.HS_APPROVAL, forecastingKeys.CLONE]
    };
  },
  computed: {
    ...mapState({
      activeNode: state => state.demand.tree.active_node
    }),
    ...mapGetters({
      editableForSomeChildren: 'demand/tree/editableForSomeChildren'
    }),
    isDisabled() {
      return !(this.$sl_tabEditable && this.$sl_hasAccess(this.$sl_features.demandSettings));
    },
    isSelect() {
      return !this.comboboxKeys.includes(this.rowKey);
    },
    isCombobox() {
      return this.rowKey === forecastingKeys.CLONE;
    },
    isApprovalCombobox() {
      return this.rowKey === forecastingKeys.HS_APPROVAL;
    },
    isRedCombobox() {
      return this.appendIcon.icon || this.appendIcon.mismatch;
    },
    comboboxTooltipValue() {
      return this.appendIcon.mismatch && this.appendIcon.title;
    }
  },
  methods: {
    ...mapActions({
      fetchItemCodes: 'project/fetchItemCodes',
      resetItemCodes: 'project/resetItemCodes'
    }),
    handleChange(val) {
      if (this.editableForSomeChildren) {
        return this.showModal(modalsId.SL_CONFIRM_MODAL, {
          title: this.$t('Web.Tree.TitlePartiallyModify'),
          text: this.$t('Web.Tree.TextPartiallyModify', { 1: this.activeNode.text }),
          icon: 'style_warning',
          confirmText: this.$t('Web.Tree.BtnProceed'),
          confirmCallback: () => this.$emit('select-change', val)
        });
      }

      this.selectValue = val;

      this.$emit('select-change', val);
    },
    handleComboboxSearch: customDebounce(function(query) {
      this.fetchItemCodes({
        query: typeof query === 'string' && query
      });
    }, 200),
    changeCombobox(value) {
      if (!value) {
        this.resetItemCodes();

        if (!this.vModel) {
          this.handleChange('');
        }

        return;
      }

      this.handleChange(value);
    },
    changeApprovalCombobox(value) {
      if (value?.value) {
        return this.handleChange(value);
      }

      const isValid = value
        && !regExp.nonDigit.test(value)
        && +value >= this.min
        && +value <= this.max;

      if (!isValid) {
        return;
      }

      this.handleChange(value);
    },
    handleElementFocus() {
      this.$emit('focus');
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/forecastingRow.scss";
</style>
