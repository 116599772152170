<template>
  <div
    v-if="isModelAvailable"
    class="dem-settings__tab-content"
  >
    <SaveAsPatternModal :id="modalsId.SAVE_AS_PATTERN" />
    <div v-if="title">
      <div class="dem-settings__toolbar">
        <div class="dem-settings__title">
          {{ title }}
        </div>
        <div
          v-if="isSaveAsPatternVisible"
          class="dem-settings__save-as-pattern"
        >
          <v-btn
            class="sl-button background--grey"
            text
            :disabled="!$sl_tabEditable"
            @click="handleSaveAsPattern"
          >
            {{ $t('Main.Ui.btMakePattern') }}
          </v-btn>
        </div>
      </div>
      <div class="dem-settings__line horizontal-line" />
    </div>
    <div class="dem-settings__header-row">
      <div class="dem-settings__header">
        {{ $t('Main.Coeff') }}
      </div>
    </div>
    <div class="dem-settings__tables">
      <div v-if="isModelCoeffAvailable">
        <div
          v-for="(item, index) of modelObj.modelCoeffs"
          :key="item.id"
          class="dem-settings__row-wrap"
        >
          <SettingsRow
            :key="updateFlag"
            class="dem-settings__row"
            :class="{
              'dem-settings__row--odd': item.editable && !(index % 2)
            }"
            :title="item.name"
            :value="getItemValue(item)"
            :edit-value="item.edit"
            :editable="item.editable"
            :foreground="item.foreground"
            :data-test-id="`model-tbl-row-${index}`"
            @change="(val) => handleRowChange({
              type: postModelType,
              item,
              val
            })"
          />
        </div>
      </div>
      <div v-if="modelObj.topDownCoeffs.length">
        <div
          v-if="isModelCoeffAvailable"
          class="dem-settings__title"
        >
          {{ $t('Main.Ui.TopDownCoeffs') }}
        </div>

        <div
          v-for="(item, index) of modelObj.topDownCoeffs"
          :key="item.id"
          class="dem-settings__row-wrap"
        >
          <SettingsRow
            :key="updateFlag"
            class="dem-settings__row"
            :class="{
              'dem-settings__row--odd': item.editable && !(index % 2)
            }"
            :title="item.name"
            :value="getItemValue(item)"
            :edit-value="item.edit"
            :editable="item.editable"
            :foreground="item.foreground"
            :data-test-id="`model-tbl-row-${index}`"
            @change="(val) => handleRowChange({
              type: postTopDownType,
              item,
              val
            })"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    v-else
    class="dem-settings__message"
  >
    {{ message }}
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import { access } from '@/mixins/access';
import SettingsRow from '@/components/Demand/DemandSettings/SettingsRow.vue';
import SaveAsPatternModal from '@/components/Modals/SeasonPattern/SaveAsPatternModal.vue';
import modalsId from '@/config/shared/modalsId.config';

export default {
  name: 'ModelTab',
  components: {
    SettingsRow,
    SaveAsPatternModal
  },
  mixins: [modal, access],
  props: {
    message: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      modalsId,
      updateFlag: false,
      postModelType: 'model',
      postTopDownType: 'topDown',
      notAvailable: 'N/A'
    };
  },
  computed: {
    ...mapGetters({
      modelObj: 'model/modelObj',
      forecastTitleCode: 'demand/chart/forecastTitleCode',
      request: 'model/request',
      isModelAvailable: 'model/isModelAvailable',
      patternNames: 'demand/seasonPattern/patternModelNames',
      activeNodeThings: 'demand/tree/activeNodeThings'
    }),
    title() {
      if (this.forecastTitleCode) {
        return this.$t(this.forecastTitleCode);
      }

      return null;
    },
    isSaveAsPatternVisible() {
      return this.forecastTitleCode === 'Main.Model.Seasonal';
    }
  },
  watch: {
    modelObj() {
      /**
       * if we send some invalid value to the server,
       * the server will send the old value.
       * Our watcher will not work due to the fact that
       * the value from the server has not changed,
       * so the value would remain the same (invalid/wrong).
       *
       * This is how I had to do a force update
       */
      this.updateFlag = !this.updateFlag;
    }
  },
  methods: {
    ...mapActions({
      handleRowChange: 'model/postModelTab',
      saveAsPattern: 'demand/seasonPattern/saveAsPattern'
    }),
    handleSaveAsPattern() {
      if (!this.$sl_tabEditable) {
        return;
      }

      this.showModal(modalsId.SAVE_AS_PATTERN, {
        patternNames: this.patternNames,
        submitCallback: this.handleSaveAsPatternSubmit
      });
    },
    handleSaveAsPatternSubmit(payload) {
      this.saveAsPattern({
        data: {
          ...payload,
          item: this.activeNodeThings.item,
          location: this.activeNodeThings.loc,
          channel: this.activeNodeThings.chan
        },
        fromChannel: 1
      });
    },
    isModelCoeffAvailable() {
      return this.modelObj.modelCoeffs.length;
    },
    getItemValue(item) {
      if (item?.val === undefined) {
        return this.notAvailable;
      }

      if (item?.unit) {
        return item.val + item.unit;
      }

      return item.val;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/tabSettings.scss";

.horizontal-line {
  width: 100%;
  height: 1px;
  background: #ccc;
}
</style>
