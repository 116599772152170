<template>
  <div class="demand-settings-skeleton">
    <div class="demand-settings-skeleton__header">
      <v-skeleton-loader
        type="text"
        max-width="40%"
      />
    </div>
    <div class="demand-settings-skeleton__table">
      <v-skeleton-loader
        type="table-row@20"
        :types="{
          'table-row': 'table-cell@2'
        }"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DemandSettingsSkeleton'
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/demand-settings-skeleton.scss";
</style>