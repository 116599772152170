<template>
  <div>
    <div
      v-if="tableData"
      id="demand-table"
      ref="table"
      class="table"
    >
      <DynamicScroller
        class="table__scroller"
        :items="tableData"
        direction="horizontal"
        :min-item-size="columnWidth"
      >
        <template #before>
          <TableHeader />
        </template>
        <template v-slot="{ item, index, active }">
          <DynamicScrollerItem
            :item="item"
            :active="active"
            :data-index="index"
            emit-resize
          >
            <TableItemCol
              :table-col="item"
              :p-known="pKnown"
              :is-disabled="isDisabledRow"
              :data-resize="index"
              class="table__column-wrap"
              :style="{
                width: `${colSizes[index] || columnWidth}px`
              }"
              :data-test-id="`demand-tbl-col-${index}`"
            /> <!-- width style is hack to work with virtual-scroller -->
          </DynamicScrollerItem>
        </template>
      </DynamicScroller>
    </div>
    <ConfirmModal
      :id="modalsId.CONFIRM"
      width="290"
      without-cancel
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import { colResize } from '@/mixins/colResize';
import { access } from '@/mixins/access';
import TableItemCol from './TableItemCol.vue';
import TableHeader from './TableHeader.vue';
import ConfirmModal from '@/components/Modals/Shared/ConfirmModal.vue';
import modalsId from '@/config/shared/modalsId.config';
import { uiSettingsKeys, uiTabByRoute } from '@/config/users/uiSettings.config';
import { dateByLocaleKey } from '@/helpers/locale/localeDate';

export default {
  name: 'Table',
  components: {
    TableItemCol,
    TableHeader,
    ConfirmModal
  },
  mixins: [modal, colResize, access],
  props: {
    serverSideColSizes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      tableScroller: null,
      cellHeight: '27px',
      minColumnWidth: 40
    };
  },
  computed: {
    ...mapState({
      chartTitle: (state) => state.demand.chart.chartTitle,
      ui_settings: (state) => state.user.ui_settings
    }),
    ...mapGetters({
      pointsNumber: 'demand/table/pointsNumber',
      table: 'demand/table/tablePoints',
      pKnown: 'project/pKnown',
      chartUnitLocale: 'demand/chart/chartUnitLocale'
    }),
    demandUiSettings() {
      return this.ui_settings[uiTabByRoute[this.$sl_routeName]];
    },
    columnWidth() {
      return this.demandUiSettings?.[uiSettingsKeys.COLUMNS_WIDTH]?.default || 80;
    },
    tableData() {
      return this.table.map(({ date, ...t }, i) => ({
        ...t,
        id: i,
        _date: date,
        date: dateByLocaleKey(date, this.chartUnitLocale)
      }));
    },
    isDisabledRow() {
      return !(this.$sl_tabEditable && this.$sl_hasAccess(this.$sl_features.demandNode));
    }
  },
  watch: {
    chartTitle() {
      this.$nextTick(this.scrollToEditableElement);
    },
    pointsNumber() {
      this.$nextTick(this.scrollToEditableElement);
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.scrollToEditableElement(() => {
        this.initResize({
          tableId: 'demand-table',
          colSelector: '.table__column-wrap',
          minWidth: this.minColumnWidth,
          resizerHeight: this.cellHeight,
          activeResizerHeight: this.cellHeight,
          lazyResize: true,
          initialColSizes: this.serverSideColSizes,
          updateSizeCallback: (data) => this.handleColResize(data)
        });
      });
    });
  },
  methods: {
    ...mapActions({
      changeUiSettings: 'user/changeUiSettings'
    }),
    handleColResize(data) {
      const clazz = this.tableData[+data.index]._date;

      this.changeUiSettings({
        key: uiSettingsKeys.COLUMNS_WIDTH,
        value: {
          clazz,
          width: data.width
        }
      });
    },
    scrollToEditableElement(callback) {
      const inputIndex = this.pKnown - 3;

      setTimeout(() => {
        const tableScroller = document.querySelector('.table__scroller');

        if (tableScroller) {
          tableScroller.scrollLeft = inputIndex * this.columnWidth;
        }

        setTimeout(() => {
          callback && callback();

          this.$emit('table-ready');
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/table";
</style>
