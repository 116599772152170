<template>
  <div class="dem-settings__row">
    <div class="dem-settings__row-title">
      {{ rowTitle }}
    </div>
    <input
      ref="input"
      v-model="inputValue"
      class="dem-settings__row-value"
      :class="{
        [`color--${inputForeground}`]: inputForeground,
        [`background--${background}`]: background !== 'deft',
        'background--editable': !isDisabled
      }"
      :disabled="isDisabled"
      @input="handleInput"
      @focus="handleFocus"
      @blur="handleBlur"
      @keypress.enter="e => e.target.blur()"
    >
  </div>
</template>

<script>
import { access } from '@/mixins/access';
import { tabsTableTitles } from '@/config/demand/demandSettings.config';
import regExp from '@/helpers/utils/regExp';

export default {
  name: 'SettingsRow',
  mixins: [access],
  props: {
    title: {
      type: [String, Array],
      required: true
    },
    value: {
      type: [String, Number],
      default: ''
    },
    editValue: {
      type: [String, Number],
      default: ''
    },
    editable: {
      type: Boolean,
      required: false
    },
    background: {
      type: String,
      default: 'deft'
    },
    foreground: {
      type: String,
      default: 'deft'
    }
  },
  data() {
    return {
      inputStarted: false,
      inputValue: this.value,
      inputForeground: this.foreground
    };
  },
  computed: {
    isDisabled() {
      return !this.editable || !this.$sl_tabEditable || !this.$sl_hasAccess(this.$sl_features.demandSettings);
    },
    titles() {
      return tabsTableTitles(this);
    },
    rowTitle() {
      if (Array.isArray(this.title)) {
        const multiple = this.title.map(item => this.$t(item));

        return multiple.join(' | ');
      }

      return this.titles[this.title] || this.$t(this.title) || this.title;
    }
  },
  watch: {
    value() {
      this.inputValue = this.value;
    },
    foreground() {
      this.inputForeground = this.foreground;
    }
  },
  methods: {
    handleInput(e) {
      if (!this.inputStarted) {
        this.inputStarted = true;
      }

      if (!regExp.negativeFloatInput.test(e.target.value)) {
        this.inputValue = e.target.value.slice(0, -1);
      }
    },
    handleFocus(e) {
      if (this.editValue) {
        this.inputValue = this.editValue;
      }

      this.$nextTick(() => {
        e.target.select();
      });
    },
    handleBlur() {
      if (!this.inputStarted) {
        this.inputValue = this.value;

        return;
      }

      if (this.inputValue !== '') {
        this.inputForeground = 'over';
      }

      this.$emit('change', this.inputValue);
      this.inputStarted = false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/demSettings/settingRow.scss";
</style>
