<template>
  <ul class="table-info__titles">
    <li class="table-info__title table-info__title--header" />
    <li
      v-for="(title, index) of titles"
      :key="title"
      class="table-info__title"
      :data-test-id="`demand-tbl-header-${header[index].class.toLowerCase()}`"
    >
      {{ title }}
    </li>
  </ul>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'TableHeader',
  computed: {
    ...mapState({
      header: state => state.demand.table.header
    }),
    ...mapGetters({
      titles: 'demand/table/headers'
    })
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/table-header";
</style>
