<template>
  <div>
    <div
      class="cell"
      @click.right.prevent
    >
      {{ tableCol.date }}
    </div>
    <!-- ACTUAL SALES -->
    <div
      v-if="isRowExists(colKeys.actual.headerClass)"
      v-tooltip="getNoteTooltipValue(tableCol.actual)"
      class="cell"
      :class="{
        'has-note': isHasNote(tableCol.actual)
      }"
      tabindex="-1"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.actual.xmlKey
      })"
      @focus.capture="() => handleCellFocus(colKeys.actual.xmlKey)"
    >
      <TableInput
        v-if="tableCol.actual"
        v-model="actualSalesValue"
        :disabled="isActualDisabled"
        :text-value="formatNumber(actualSalesValue)"
        :class="getCellClasses(colKeys.actual.xmlKey)"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(colKeys.actual.xmlKey, value)"
      />
    </div>
    <!-- LOWER ECHELON DEMAND -->
    <div
      v-if="isRowExists(colKeys.lowerEchelonDemand.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.lowerEchelonDemand.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.lowerEchelonDemand.xmlKey)"
    >
      <div
        v-if="tableCol.lowerEchelonDemand"
        class="cell__text"
        :class="getCellClasses(colKeys.lowerEchelonDemand.xmlKey)"
      >
        {{ formatNumber(tableCol.lowerEchelonDemand.val) }}
      </div>
    </div>
    <!-- MATERIAL CONSUMPTION -->
    <div
      v-if="isRowExists(colKeys.materialConsumption.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.materialConsumption.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.materialConsumption.xmlKey)"
    >
      <div
        v-if="tableCol.materialConsumption"
        class="cell__text"
        :class="getCellClasses(colKeys.materialConsumption.xmlKey)"
      >
        {{ formatNumber(tableCol.materialConsumption.val) }}
      </div>
    </div>
    <!-- STATISTICAL FORECAST -->
    <div
      v-if="isRowExists(colKeys.statisticalForecast.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.statisticalForecast.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.statisticalForecast.xmlKey)"
    >
      <div
        v-if="isDisplayStatisticalForecast"
        class="cell__text"
        :class="getCellClasses(colKeys.statisticalForecast.xmlKey)"
      >
        {{ formatNumber(tableCol.statisticalForecast.val) }}
      </div>
    </div>
    <!-- CUSTOM ROWS OVERRIDES -->
    <template
      v-for="(ov, i) in overrideCount"
    >
      <div
        v-if="isForecastExist(i)"
        :key="ov"
        v-tooltip="getNoteTooltipValue(tableCol.customRowOverrides[i])"
        class="cell"
        :class="{
          'color--over': tableCol.customRowOverrides.editable,
          'has-note': isHasNote(tableCol.customRowOverrides[i])
        }"
        tabindex="-1"
        @click.right.prevent="handleContextMenuClick({
          event: $event,
          cellKey: colKeys.customRowOverrides.xmlKey,
          index: i
        })"
        @focus.capture="() => handleCellFocus(colKeys.customRowOverrides.xmlKey, i)"
      >
        <TableInput
          v-if="tableCol.customRowOverrides[i] !== undefined"
          v-tooltip="getFormulaTooltip(i)"
          :value="getForecastOverrideValue(i)"
          :text-value="getForecastOverrideTextValue(i)"
          :disabled="isForecastDisabled(tableCol.customRowOverrides[i])"
          :class="getForecastOverrideClasses(i)"
          :max-length="formulaMaxLength"
          :invalid="getFormulaError(i)"
          type="formula"
          focus-visible
          @input="(value) => updateForecastOverridesCell(value, i)"
          @submit="(value) => submitPredictionOverride(value, i)"
        />
      </div>
    </template>
    <!-- UPPER CONF LIMIT -->
    <div
      v-if="isRowExists(colKeys.UpperConfLimit.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.UpperConfLimit.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.UpperConfLimit.xmlKey)"
    >
      <div
        v-if="tableCol.upperConfLimit"
        class="cell__text"
        :class="getCellClasses(colKeys.UpperConfLimit.xmlKey)"
      >
        {{ formatNumber(tableCol.upperConfLimit.val) }}
      </div>
    </div>
    <!-- LOWER CONF LIMIT -->
    <div
      v-if="isRowExists(colKeys.LowerConfLimit.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.LowerConfLimit.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.LowerConfLimit.xmlKey)"
    >
      <div
        v-if="tableCol.lowerConfLimit"
        class="cell__text"
        :class="getCellClasses(colKeys.LowerConfLimit.xmlKey)"
      >
        {{ formatNumber(tableCol.lowerConfLimit.val) }}
      </div>
    </div>
    <!-- AVERAGE SALES PRICE -->
    <div
      v-if="isRowExists(colKeys.AvgPrice.headerClass)"
      class="cell"
      tabindex="-1"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.AvgPrice.xmlKey
      })"
      @focus.capture="() => handleCellFocus(colKeys.AvgPrice.xmlKey)"
    >
      <TableInput
        v-if="tableCol.AvgPrice"
        v-model="avgPriceValue"
        :disabled="isAvgPriceDisabled"
        :class="getCellClasses(colKeys.AvgPrice.xmlKey)"
        :input-regexp="regExp.floatInput"
        :submit-regexp="regExp.floatSubmit"
        :text-value="formatNumber(avgPriceValue)"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(colKeys.AvgPrice.xmlKey, value)"
      />
    </div>
    <!-- REVENUE -->
    <div
      v-if="isRowExists(colKeys.Revenue.headerClass)"
      class="cell"
      tabindex="-1"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: revenueType.xmlKey
      })"
      @focus.capture="() => handleCellFocus(revenueType.xmlKey)"
    >
      <TableInput
        v-model="revenueType.value"
        :disabled="revenueType.disabled"
        :class="getCellClasses(revenueType.xmlKey)"
        :input-regexp="regExp.floatInput"
        :submit-regexp="regExp.floatSubmit"
        :text-value="formatNumber(revenueType.value)"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(revenueType.xmlKey, value)"
      />
    </div>
    <!-- PROFIT -->
    <div
      v-if="isRowExists(colKeys.profit.headerClass)"
      class="cell"
      tabindex="-1"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.profit.xmlKey
      })"
      @focus.capture="() => handleCellFocus(colKeys.profit.xmlKey)"
    >
      <TableInput
        v-if="tableCol.profit"
        v-model="profitValue"
        :disabled="isDisabled || !tableCol.profit.editable"
        :class="getCellClasses(colKeys.profit.xmlKey)"
        :input-regexp="regExp.floatInput"
        :submit-regexp="regExp.floatSubmit"
        :text-value="formatNumber(profitValue)"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(colKeys.profit.xmlKey, value)"
      />
    </div>
    <!-- ON HAND -->
    <div
      v-if="isRowExists(colKeys.OnHand.headerClass)"
      v-tooltip="getNoteTooltipValue(tableCol.OnHand)"
      class="cell"
      :class="{
        'has-note': isHasNote(tableCol.OnHand)
      }"
      tabindex="-1"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.OnHand.xmlKey
      })"
      @focus.capture="() => handleCellFocus(colKeys.OnHand.xmlKey)"
    >
      <TableInput
        v-if="tableCol.OnHand"
        v-model="onHandValue"
        :disabled="isOnHandDisabled"
        :class="getCellClasses(colKeys.OnHand.xmlKey)"
        :input-regexp="regExp.negativeFloatInput"
        :submit-regexp="regExp.floatSubmit"
        :text-value="formatNumber(onHandValue)"
        :max-length="9"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(colKeys.OnHand.xmlKey, value)"
      />
    </div>
    <!-- STOCKOUT DAYS -->
    <div
      v-if="isRowExists(colKeys.LostDays.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.LostDays.xmlKey
      })"
      @focus.capture="() => handleCellFocus(colKeys.LostDays.xmlKey)"
    >
      <TableInput
        v-if="tableCol.LostDays"
        v-model="lostDaysValue"
        :disabled="isDisabled || !tableCol.LostDays.editable"
        :class="getCellClasses(colKeys.LostDays.xmlKey)"
        :input-regexp="regExp.floatInput"
        :submit-regexp="regExp.floatSubmit"
        :max-length="4"
        focus-visible
        @input="startInput"
        @submit="(value) => submitValue(colKeys.LostDays.xmlKey, value)"
      />
    </div>
    <!-- HISTORICAL SERVICE LEVEL -->
    <div
      v-if="isRowExists(colKeys.ServiceLevel.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.ServiceLevel.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.ServiceLevel.xmlKey)"
    >
      <div
        v-if="tableCol.ServiceLevel"
        class="cell__text"
        :class="getCellClasses(colKeys.ServiceLevel.xmlKey)"
      >
        {{ tableCol.ServiceLevel.val }}
      </div>
    </div>
    <!-- PROJECTED INVENTORY LEVELS -->
    <div
      v-if="isRowExists(colKeys.remainder.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.remainder.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.remainder.xmlKey)"
    >
      <div
        v-if="tableCol.remainder"
        class="cell__text"
        :class="getCellClasses(colKeys.remainder.xmlKey)"
      >
        {{ formatNumber(tableCol.remainder.val) }}
      </div>
    </div>
    <!-- PROJECTED INVENTORY DAYS OF SUPPLY -->
    <div
      v-if="isRowExists(colKeys.projectedDaysOfSupply.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.projectedDaysOfSupply.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.projectedDaysOfSupply.xmlKey)"
    >
      <div
        v-if="tableCol.projectedDaysOfSupply"
        class="cell__text"
        :class="getCellClasses(colKeys.projectedDaysOfSupply.xmlKey)"
      >
        {{ formatNumber(tableCol.projectedDaysOfSupply.val) }}
      </div>
    </div>
    <!-- ORDERING PLAN -->
    <div
      v-if="isRowExists(colKeys.toOrder.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.toOrder.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.toOrder.xmlKey)"
    >
      <div
        v-if="tableCol.toOrder"
        class="cell__text"
        :class="getCellClasses(colKeys.toOrder.xmlKey)"
      >
        {{ formatNumber(tableCol.toOrder.val) }}
      </div>
    </div>
    <!-- ORDERING AVAILABILITY -->
    <div
      v-if="isRowExists(colKeys.allowOrder.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.allowOrder.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.allowOrder.xmlKey)"
    >
      <v-checkbox
        v-if="tableCol.allowOrder"
        :input-value="allowOrderValue"
        :disabled="IsDisabledOrderingAvailability"
        class="sl-checkbox"
        @change="handleOrderingAvailabilityChange"
      />
    </div>
    <!-- TO RECEIVE -->
    <div
      v-if="isRowExists(colKeys.onorder.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.onorder.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.onorder.xmlKey)"
    >
      <div
        v-if="tableCol.onorder"
        class="cell__text"
        :class="getCellClasses(colKeys.onorder.xmlKey)"
      >
        {{ formatNumber(tableCol.onorder.val) }}
      </div>
    </div>
    <!-- TO SHIP -->
    <div
      v-if="isRowExists(colKeys.backorder.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.backorder.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.backorder.xmlKey)"
    >
      <div
        v-if="tableCol.backorder"
        class="cell__text"
        :class="getCellClasses(colKeys.backorder.xmlKey)"
      >
        {{ formatNumber(tableCol.backorder.val) }}
      </div>
    </div>
    <!-- CUSTOM OUTLIER -->
    <div
      v-if="isRowExists(colKeys.customOutlier.headerClass)"
      tabindex="-1"
      class="cell"
      @click.right.prevent="handleContextMenuClick({
        event: $event,
        cellKey: colKeys.customOutlier.xmlKey
      })"
      @focus="() => handleCellFocus(colKeys.customOutlier.xmlKey)"
    >
      <div
        v-if="tableCol.customOutlier"
        class="cell__checkbox"
      >
        <v-checkbox
          :key="isOutlierChecked + isOutlierMixed"
          :disabled="isDisabled"
          :input-value="isOutlierChecked"
          :indeterminate="isOutlierMixed"
          :readonly="isOutlierMixed"
          class="sl-checkbox"
          @click="handleOutlierClick"
          @change="handleOutlierChange"
        />
        <div
          v-if="isOutlierOverridden"
          v-tooltip="$t('Main.DemandTable.ToolTips.ClearOverrides')"
          class="cell__checkbox-arrow"
          @click="popUpClear()"
        >
          <icon data="@icon/revert_blue.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { modal } from '@/mixins/modal';
import TableInput from '@/components/Shared/Form/TableInput.vue';
import modalsId from '@/config/shared/modalsId.config';
import { colKeys, inputPatterns, pointTypes } from '@/config/demand/table.config';
import { formatNumber } from '@/helpers/format/formatNumber';
import { getNoteTooltip } from '@/helpers/shared/note';
import { getErrorTooltip } from '@/helpers/shared/tooltip';
import regExp from '@/helpers/utils/regExp';

export default {
  components: {
    TableInput
  },
  mixins: [modal],
  props: {
    tableCol: {
      type: Object,
      required: true
    },
    isDisabled: {
      type: Boolean,
      required: true
    },
    pKnown: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      modalsId,
      colKeys,
      inputPatterns,
      regExp,
      formatNumber,
      nonzeroSales: '',
      inputStarted: false,
      clearMessage: false,
      clearMixed: false,
      formulaMaxLength: 200
    };
  },
  inject: [
    'contextMenuHandler',
    'focusCellHandler'
  ],
  computed: {
    ...mapState({
      storeHeader: (state) => state.demand.table.header,
      canUpdate: (state) => state.lastChanges.canUpdate
    }),
    ...mapGetters({
      forecastVersionNames: 'demand/table/forecastVersionNames',
      headerItems: 'demand/table/visibleRows'
    }),
    overrideCount() {
      const count = this.forecastVersionNames?.length;

      return count ? count - 1 : 0;
    },

    /**ACTUAL SALES**/
    actualSalesValue: {
      get() {
        return this.tableCol.actual.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.actual.xmlKey,
          value
        });
      }
    },
    isActualDisabled() {
      return this.isDisabled || !this.tableCol.actual?.editable;
    },
    isDisplayStatisticalForecast() {
      return this.tableCol.statisticalForecast && this.tableCol.pointType !== pointTypes.NONE;
    },

    /**AVERAGE SALES**/
    avgPriceValue: {
      get() {
        return this.tableCol.AvgPrice.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.AvgPrice.xmlKey,
          value
        });
      }
    },
    isAvgPriceDisabled() {
      return this.isDisabled || !this.tableCol.AvgPrice.editable;
    },

    /**REVENUE**/
    predictedRevenueValue: {
      get() {
        return this.tableCol.predictedRevenue?.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.predictedRevenue.xmlKey,
          value
        });
      }
    },
    revenueValue: {
      get() {
        return this.tableCol.Revenue?.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.Revenue.xmlKey,
          value
        });
      }
    },
    isPredictedRevenue() {
      return this.tableCol.p >= this.pKnown;
    },
    isPredictedRevenueDisabled() {
      return this.isDisabled
        || (this.tableCol.predictedRevenue && !this.tableCol.predictedRevenue.editable);
    },
    isRevenueDisabled() {
      return this.isDisabled || this.tableCol.p < this.pKnown;
    },
    revenueType() {
      if (this.isPredictedRevenue) {
        return {
          xmlKey: colKeys.predictedRevenue.xmlKey,
          value: this.predictedRevenueValue,
          disabled: this.isPredictedRevenueDisabled
        };
      }

      return {
        xmlKey: colKeys.Revenue.xmlKey,
        value: this.revenueValue,
        disabled: this.isRevenueDisabled
      };
    },

    /** PROFIT **/
    profitValue: {
      get() {
        return this.tableCol.profit.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.profit.xmlKey,
          value
        });
      }
    },

    /**ON HAND**/
    onHandValue: {
      get() {
        return this.tableCol.OnHand.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.OnHand.xmlKey,
          value
        });
      }
    },
    isOnHandDisabled() {
      return this.isDisabled || !this.tableCol.OnHand?.editable;
    },

    /**STOCKOUT DAYS**/
    lostDaysValue: {
      get() {
        return this.tableCol.LostDays.val;
      },
      set(value) {
        this.updateTableCell({
          index: this.tableCol.p,
          key: this.colKeys.LostDays.xmlKey,
          value
        });
      }
    },
    /** Ordering availability **/
    IsDisabledOrderingAvailability() {
      return this.isDisabled || !this.tableCol.allowOrder.editable;
    },
    allowOrderValue() {
      return +this.tableCol.allowOrder?.val;
    },
    /**CUSTOM OUTLIER**/
    isOutlierChecked() {
      return this.tableCol.customOutlier?.val === 'checked';
    },
    isOutlierMixed() {
      return this.tableCol.customOutlier?.val === 'mixed';
    },
    isOutlierOverridden() {
      return this.tableCol.customOutlier?.isOverridden;
    }
  },
  mounted() {
    this.nonzeroSales = '';
  },
  methods: {
    ...mapActions({
      setUpdateStatus: 'lastChanges/setUpdateStatus',
      updateTableCell: 'demand/table/updateTableCell',
      updateForecastOverrideCell: 'demand/table/updateForecastOverrideCell'
    }),
    disabledCellByKey(override) {
      return {
        [colKeys.customRowOverrides.xmlKey]: this.isForecastDisabled(override),
        [colKeys.actual.xmlKey]: this.isActualDisabled,
        [colKeys.OnHand.xmlKey]: this.isOnHandDisabled
      };
    },
    isForecastDisabled(override) {
      return this.isDisabled || !override?.editable;
    },
    handleOrderingAvailabilityChange(value) {
      this.inputStarted = true;

      this.updateTableCell({
        index: this.tableCol.p,
        key: this.colKeys.allowOrder.xmlKey,
        value
      });

      this.submitValue(this.colKeys.allowOrder.xmlKey, this.getCheckboxSubmitValue(value));
    },
    getCheckboxSubmitValue(value) {
      return value ? 'SetChecked' : 'SetUnchecked';
    },
    getCellClasses(key) {
      const cellForeground = this.tableCol[key]?.foreground;

      if (key === colKeys.materialConsumption.xmlKey) {
        return 'color--veau';
      }

      return {
        [`color--${cellForeground}`]: cellForeground
      };
    },
    getFormulaTooltip(i) {
      return getErrorTooltip(this.tableCol.customRowOverrides[i].tooltip);
    },
    getFormulaError(i) {
      return !!this.tableCol.customRowOverrides[i].tooltip;
    },
    getOverrideByIndex(i) {
      return this.tableCol.customRowOverrides[i];
    },
    checkIsOverrideError(override) {
      return `${override?.val}`.startsWith('#');
    },
    getForecastOverrideValue(i) {
      const override = this.getOverrideByIndex(i);

      return override?.formula ?? override?.val;
    },
    getForecastOverrideTextValue(i) {
      const override = this.getOverrideByIndex(i);

      return this.checkIsOverrideError(override)
        ? override?.val
        : formatNumber(override?.val);
    },
    getForecastOverrideClasses(i) {
      const overrideForeground = this.tableCol.customRowOverrides[i]?.foreground;

      return {
        [`color--${overrideForeground}`]: overrideForeground
      };
    },
    isRowExists(header) {
      return this.headerItems.includes(header);
    },
    isHasNote(cell) {
      return cell?.note;
    },
    getNoteTooltipValue(cell) {
      if (!this.isHasNote(cell)) {
        return '';
      }

      return getNoteTooltip(cell?.note);
    },
    startInput() {
      this.inputStarted = true;

      if (this.canUpdate) {
        this.setUpdateStatus(false);
      }
    },
    stopInput() {
      this.inputStarted = false;

      if (!this.canUpdate) {
        this.setUpdateStatus(true);
      }
    },
    async submitValue(header, value) {
      try {
        const isClearPredictedRevenue = header === this.colKeys.predictedRevenue.xmlKey && !value;

        if (!this.inputStarted || isClearPredictedRevenue) {
          return;
        }

        const payload = {
          row: {
            name: this.colKeys[header].requestKey
          },
          col: this.tableCol.p,
          val: value === ''
            ? null
            : isNaN(+value)
              ? value
              : +value
        };

        await this.$store.dispatch('demand/table/updateTable', payload);
      } finally {
        this.stopInput();
      }
    },
    async submitPredictionOverride(value, index) {
      try {
        if (!this.inputStarted) {
          return;
        }

        await this.$store.dispatch('demand/table/updateOverridePrediction', {
          p: this.tableCol.p,
          val: value,
          isOver: index
        });
      } finally {
        this.stopInput();
      }
    },
    /*Forecast*/
    updateForecastOverridesCell(value, index) {
      this.updateForecastOverrideCell({
        index: this.tableCol.p,
        key: this.colKeys.customRowOverrides.xmlKey,
        overrideIndex: index,
        value
      });

      this.startInput();
    },
    isForecastExist(i) {
      const globalOverrideIndex = this.storeHeader
        .findIndex(h => h.class === this.colKeys.customRowOverrides.headerClass);

      const header = this.storeHeader[globalOverrideIndex + i];

      return header?.visible;
    },
    getFocusCellPayload(cellKey, index = null) {
      const override = this.tableCol.customRowOverrides[index];
      const value = override?.ovValue ?? override?.val ?? this.tableCol[cellKey]?.val ?? '';
      const position = this.tableCol.p;
      const cell = index === null
        ? this.tableCol[cellKey]
        : this.tableCol.customRowOverrides[index];

      const note = cell?.note ?? null;

      const cellIndex = this.forecastVersionNames?.[index + 1]?.index || index;

      return {
        value,
        name: override?.name || '',
        disabled: this.disabledCellByKey(this.tableCol.customRowOverrides[index])[cellKey],
        index: cellIndex,
        position,
        cellKey,
        note,
        cell
      };
    },
    handleContextMenuClick({ event, cellKey, index }) {
      this.handleCellFocus(cellKey, index);

      this.contextMenuHandler(event, this.getFocusCellPayload(cellKey, index));
    },
    handleCellFocus(cellKey, index) {
      this.focusCellHandler(this.getFocusCellPayload(cellKey, index));
    },
    /*Outlier*/
    handleOutlierClick() {
      if (this.isOutlierMixed && !this.isDisabled) {
        this.showModal(modalsId.CONFIRM, {
          title: this.$t('Common.AreYouSure'),
          text: this.$t('Main.OvCheckboxQuestion'),
          yesHandler: () => this.handleOutlierChange(false)
        });
      }
    },
    handleOutlierChange(isChecked) {
      this.updateOutlier(this.getCheckboxSubmitValue(isChecked));
    },
    handleOutlierClear() {
      this.updateOutlier('Clear');
    },
    updateOutlier(value) {
      this.inputStarted = true;

      this.submitValue(this.colKeys.customOutlier.xmlKey, `${value}`);
      this.hideModal(modalsId.CONFIRM);
    },
    popUpClear() {
      if (!this.isDisabled) {
        if (this.isOutlierOverridden && this.isOutlierMixed) {
          this.showModal(modalsId.SL_CONFIRM_MODAL, {
            icon: 'style_warning_double',
            title: this.$t('Web.Modals.ActionConfirm.TitleClearOverrides'),
            confirmText: this.$t('Web.Modals.ActionConfirm.ButtonClear'),
            confirmCallback: this.handleOutlierClear
          });
        } else {
          this.handleOutlierClear();
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/table/cell.scss";
</style>
