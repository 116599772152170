<template>
  <div
    ref="treeItem"
    v-tooltip="getNoteTooltipValue()"
    class="tree-item"
    :class="{
      'color--over': isBlue(),
      'tree-item--leaf': !hasChilds()
    }"
    tabindex="0"
    @click.stop="handleLabelClicks"
    @click.right.exact.prevent.stop="handleSelectItem"
    @click.middle.exact.prevent.stop="handleMiddleClick"
  >
    <i
      v-for="depth in item.depth"
      :key="depth"
      class="tree-item__deep-divider"
    />
    <icon
      v-if="hasChilds()"
      :class="{ 'tree-item__arrow--opened': isOpen() }"
      class="size-16 tree-item__arrow"
      data="@icon/arrow-down.svg"
      @click.stop="handleFolderToggle"
    />
    <div
      class="tree-item__label"
      :class="{
        'tree-item__label--selected': isActiveItem,
        'tree-item__label--inactive': isInactive()
      }"
    >
      <icon
        v-if="isWarning()"
        data="@tree/warn_collored.svg"
        class="tree-item__icon"
      />
      <icon
        v-else-if="isChecked()"
        data="@tree/approve_green.svg"
        class="tree-item__icon"
      />
      <icon
        v-for="(imgItem, imgIndex) in itemIcons"
        :key="imgItem.image + imgIndex"
        :data="imgItem.image"
        class="tree-item__icon"
        :class="{
          'tree-item__icon--grey': imgItem.class === 'grey-icon',
          'tree-item__icon--active': isActiveItem,
          'fill-off': imgItem.new
        }"
      />
      {{ item.text }}
      <div
        v-if="isStockShown() && checkIsStockShown(item.overstock)"
        class="stock-amount__item stock-amount__item--overstock"
      >
        {{ item.overstock }}
      </div>
      <div
        v-if="isStockShown() && checkIsStockShown(item.stockout)"
        class="stock-amount__item stock-amount__item--stockout"
      >
        {{ item.stockout }}
      </div>
    </div>
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import { access } from '@/mixins/access';
import { ROOT_NODE_ID, treeItemStates } from '@/config/demand/tree/tree.config';
import { nodeFlags, treeFgs } from '@/config/shared/fgs.config';
import { getNoteKey, getNoteTooltip, getTooltipContent } from '@/helpers/shared/note';
import { openLink } from '@/helpers/shared/webAPI';

export default {
  name: 'TreeVirtualItem',
  mixins: [access],
  props: {
    item: {
      type: Object,
      required: true
    },
    nextItem: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    itemIcons: {
      type: Array,
      required: true
    },
    hideStock: Boolean,
    isActiveItem: Boolean
  },
  data() {
    return {
      nodeFlags,
      treeFgs,
      treeItemStates,
      clickTimer: null,
      clickCount: 0,
      clickDelay: 350
    };
  },
  methods: {
    ...mapActions({
      openTreeChild: 'demand/tree/openTreeChild',
      hideTreeChild: 'demand/tree/hideTreeChild',
      setActiveNode: 'demand/tree/setActiveNode',
      fetchNodePath: 'demand/tree/fetchNodePath',
      fetchNote: 'note/fetchNote'
    }),
    isStockShown() {
      return !this.hideStock
        && (this.$sl_hasAccess(this.$sl_features.salesData)
          && this.$sl_hasAccess(this.$sl_features.purchasingData))
        && (this.checkIsStockShown(this.item.overstock)
          || this.checkIsStockShown(this.item.stockout));
    },
    getNoteTooltipValue() {
      if (!(this.item.nodeFlags & nodeFlags.HAS_NOTE)) {
        return;
      }

      if (this.getItemNote()) {
        return getNoteTooltip(this.getItemNote());
      }

      return {
        theme: 'dark',
        content: this.loadItemNote,
        html: true
      };
    },
    getItemNote() {
      return this.getNodeNotes()[getNoteKey(this.getItemThings())];
    },
    getItemThings() {
      return {
        type: this.item.uniId.type || '',
        item: this.item.uniId.item || '',
        loc: this.item.uniId.loc || '',
        chan: this.item.uniId.chan || ''
      };
    },
    getNodeNotes() {
      return this.$store.state.note.nodeNotes;
    },
    hasChilds() {
      return this.item.hasChilds;
    },
    isOpen() {
      return this.hasChilds() && this.item.depth < this.nextItem.depth;
    },
    isBlue() {
      return !this.hasChilds() && this.item.nodeFlags & nodeFlags.TIMEPOINTS_OVERRIDEN;
    },
    isWarning() {
      return this.item.state !== treeItemStates.CHECKED && this.item.state !== treeItemStates.NORMAL;
    },
    isChecked() {
      return this.item.state === treeItemStates.CHECKED;
    },
    isInactive() {
      return this.item.fgs & treeFgs.INACTIVE_NODE;
    },
    handleLabelClicks() {
      if (!this.isActiveItem) {
        this.handleSelectItem();
      }

      this.clickCount++;

      if (this.clickCount === 1) {
        this.clickTimer = setTimeout(() => {
          this.clickCount = 0;
        }, this.clickDelay);
      } else {
        clearTimeout(this.clickTimer);
        this.handleFolderToggle();
        this.clickCount = 0;
      }
    },
    async handleFolderToggle() {
      if (!this.hasChilds()) {
        return;
      }

      if (this.isOpen()) {
        this.hideTreeChild({
          nodeId: this.item.nodeId,
          index: this.index
        });

        // kostyl for navigation focus after virtual scroller update
        return setTimeout(() => {
          this.$refs.treeItem.focus();
        }, 700);
      }

      await this.openTreeChild({
        nodeId: this.item.nodeId,
        index: this.index
      });
    },
    handleSelectItem() {
      this.setActiveNode({
        node: this.item,
        clearSearch: true
      });
    },
    async handleMiddleClick(e) {
      e.preventDefault();

      const path = await this.fetchNodePath({
        nodeId: this.item.nodeId
      });
      const id = path.at(-1)?.id ?? ROOT_NODE_ID;

      openLink(`${window.location.origin}/demand/${id}`, true);
    },
    async loadItemNote() {
      const note = await this.fetchNote(this.getItemThings());

      return getTooltipContent(note);
    },
    checkIsStockShown(price) {
      if (!price) {
        return '';
      }

      return +price !== 0 && price !== 'nan';
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-virtual-item.scss";
</style>
