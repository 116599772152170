var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isModelAvailable)?_c('div',{staticClass:"dem-settings__tab-content"},[_c('SaveAsPatternModal',{attrs:{"id":_vm.modalsId.SAVE_AS_PATTERN}}),(_vm.title)?_c('div',[_c('div',{staticClass:"dem-settings__toolbar"},[_c('div',{staticClass:"dem-settings__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isSaveAsPatternVisible)?_c('div',{staticClass:"dem-settings__save-as-pattern"},[_c('v-btn',{staticClass:"sl-button background--grey",attrs:{"text":"","disabled":!_vm.$sl_tabEditable},on:{"click":_vm.handleSaveAsPattern}},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.btMakePattern'))+" ")])],1):_vm._e()]),_c('div',{staticClass:"dem-settings__line horizontal-line"})]):_vm._e(),_c('div',{staticClass:"dem-settings__header-row"},[_c('div',{staticClass:"dem-settings__header"},[_vm._v(" "+_vm._s(_vm.$t('Main.Coeff'))+" ")])]),_c('div',{staticClass:"dem-settings__tables"},[(_vm.isModelCoeffAvailable)?_c('div',_vm._l((_vm.modelObj.modelCoeffs),function(item,index){return _c('div',{key:item.id,staticClass:"dem-settings__row-wrap"},[_c('SettingsRow',{key:_vm.updateFlag,staticClass:"dem-settings__row",class:{
            'dem-settings__row--odd': item.editable && !(index % 2)
          },attrs:{"title":item.name,"value":_vm.getItemValue(item),"edit-value":item.edit,"editable":item.editable,"foreground":item.foreground,"data-test-id":("model-tbl-row-" + index)},on:{"change":function (val) { return _vm.handleRowChange({
            type: _vm.postModelType,
            item: item,
            val: val
          }); }}})],1)}),0):_vm._e(),(_vm.modelObj.topDownCoeffs.length)?_c('div',[(_vm.isModelCoeffAvailable)?_c('div',{staticClass:"dem-settings__title"},[_vm._v(" "+_vm._s(_vm.$t('Main.Ui.TopDownCoeffs'))+" ")]):_vm._e(),_vm._l((_vm.modelObj.topDownCoeffs),function(item,index){return _c('div',{key:item.id,staticClass:"dem-settings__row-wrap"},[_c('SettingsRow',{key:_vm.updateFlag,staticClass:"dem-settings__row",class:{
            'dem-settings__row--odd': item.editable && !(index % 2)
          },attrs:{"title":item.name,"value":_vm.getItemValue(item),"edit-value":item.edit,"editable":item.editable,"foreground":item.foreground,"data-test-id":("model-tbl-row-" + index)},on:{"change":function (val) { return _vm.handleRowChange({
            type: _vm.postTopDownType,
            item: item,
            val: val
          }); }}})],1)})],2):_vm._e()])],1):_c('div',{staticClass:"dem-settings__message"},[_vm._v(" "+_vm._s(_vm.message)+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }