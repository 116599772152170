<template>
  <PageWrapper :loading="isLoading">
    <Toolbar
      @toggle-approve="approve"
      @needs-attention="toggleAttentionItemState"
      @toggle-checked="toggleCheckedItemState"
      @add-note="handleAddNote"
    />
    <div class="data-wrap">
      <TreeWrapper />
      <Data />
    </div>
    <NoteModal :id="modalsId.ADD_NOTE" />
    <template #loader>
      <DemandSkeleton />
    </template>
  </PageWrapper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { modal } from '@/mixins/modal';
import TreeWrapper from '@/components/Demand/Tree/TreeWrapper.vue';
import Data from '@/components/Demand/Data.vue';
import Toolbar from '@/components/Demand/Toolbar/Toolbar.vue';
import PageWrapper from '@/components/Shared/PageWrapper.vue';
import DemandSkeleton from '@/components/Skeleton/DemandSkeleton.vue';
import NoteModal from '@/components/Modals/Shared/NoteModal';
import { componentNames } from '@/config/app.config.js';
import modalsId from '@/config/shared/modalsId.config';
import { sendRequests } from '@/helpers/lastChange/sendRequests.js';
import { ROOT_NODE_ID } from '@/config/demand/tree/tree.config';

export default {
  name: 'Demand',
  components: {
    NoteModal,
    PageWrapper,
    Data,
    Toolbar,
    TreeWrapper,
    DemandSkeleton
  },
  mixins: [modal],
  data() {
    return {
      modalsId,
      isLoading: false
    };
  },
  computed: {
    ...mapState({
      treeState: state => state.demand.tree
    }),
    ...mapGetters({
      treeActivePathId: 'demand/tree/treeActivePathId'
    })
  },
  watch: {
    treeActivePathId(val) {
      this.$router.push({
        params: {
          id: val ?? ROOT_NODE_ID
        }
      });
    }
  },
  async beforeMount() {
    try {
      this.isLoading = true;

      const id = this.$route.params.id;

      if (id) {
        const isValid = /^\d+$/gi.test(id);
        const path = isValid
          ? [...new Set([ROOT_NODE_ID, +id])].map(id => ({ id }))
          : [];

        await this.setActiveNodePath(path);

        if (!isValid) {
          this.$router.push({
            params: {
              ...this.$route.params,
              id: undefined
            }
          });
        }
      } else {
        this.$router.push({
          params: {
            ...this.$route.params,
            id: this.treeActivePathId
          }
        });
      }

      await sendRequests(componentNames.DEMAND);
    } finally {
      setTimeout(() => {
        this.isLoading = false;
      }, 100);

      if (this.treeState.is_link_redirect) {
        this.setIsLinkRedirect(false);
      }
    }
  },
  beforeRouteLeave(_, __, next) {
    this.resetTreeSearch();

    next();
  },
  methods: {
    ...mapActions({
      toggleAttentionItemState: 'demand/tree/toggleAttentionItemState',
      toggleCheckedItemState: 'demand/tree/toggleCheckedItemState',
      approve: 'demand/tree/approve',
      setIsLinkRedirect: 'demand/tree/setIsLinkRedirect',
      resetTreeSearch: 'demand/tree/resetSearch',
      setActiveNodePath: 'demand/tree/setActiveNodePath',
      fetchTree: 'demand/tree/fetchTree'
    }),
    handleAddNote() {
      this.showModal(modalsId.ADD_NOTE);
    }
  }
};
</script>

<style lang="scss">
.data-wrap {
  display: flex;
  max-width: 100%;
}
</style>
