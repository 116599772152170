<template>
  <div class="demand-skeleton">
    <ToolbarSkeleton />
    <div class="demand-skeleton__tree">
      <v-skeleton-loader
        type="paragraph@4"
      />
      <v-skeleton-loader
        type="sentences@2"
      />
    </div>
    <div class="demand-skeleton__table">
      <v-skeleton-loader
        type="table-row-divider@10"
        :types="{
          'table-row': 'table-cell@7'
        }"
      />
    </div>
    <div class="demand-skeleton__chart">
      <v-sparkline
        :fill="false"
        :color="'#ccc'"
        :line-width="1"
        :height="'100%'"
        :smooth="2"
        :value="sparklineConfig"
      />
      <div class="demand-skeleton__settings">
        <v-skeleton-loader
          type="heading"
          width="100%"
        />
        <v-skeleton-loader
          type="sentences@10"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ToolbarSkeleton from '@/components/Skeleton/ToolbarSkeleton.vue';

export default {
  name: 'DemandSkeleton',
  components: {
    ToolbarSkeleton
  },
  data() {
    return {
      sparklineConfig: [2, 3, 2, 1, 1, 3, 2, 4, 5, 2, 4, 3, 4, 6]
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/skeleton/demand-skeleton.scss";
</style>
