var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({ref:"treeRef",staticClass:"tree-wrapper__tree",attrs:{"tabindex":"-1"},on:{"focus":_vm.handleTreeFocus,"blur":_vm.handleTreeBlur}},'div',_vm.$attrs,false),[_c('div',{staticClass:"tree",class:{
      'loading': _vm.isFetching
    }},[_c('RecycleScroller',{key:_vm.reset,ref:"treeScroller",staticClass:"tree-scroller",attrs:{"items":_vm.treeItems,"item-size":_vm.treeItemSize,"key-field":"nodeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var item = ref.item;
    var index = ref.index;
return [_c('TreeVirtualItem',{ref:("tree-virtual-item-" + (item.nodeId)),attrs:{"item":item,"index":index,"next-item":_vm.treeItems[index + 1] || {},"hide-stock":_vm.wantHideDistortions,"item-icons":_vm.getItemIcons(item),"is-active-item":_vm.checkIsActive(item)},nativeOn:{"contextmenu":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleContextMenu($event)},"keyup":function($event){$event.preventDefault();$event.stopPropagation();return _vm.handleKeyboardNavigation({
          event: $event,
          nodeId: item.nodeId,
          index: index
        })}}})]}}])})],1),_c('TreeContextMenu',{ref:"slTreeContextMenu"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }