<template>
  <vue-resizable
    class="tree-wrapper"
    :class="{
      'resizable-min': isResizableMin,
      'loading': isFetching
    }"
    :active="vueResizableConfig.active"
    :width="vueResizableConfig.width"
    :min-width="vueResizableConfig.minWidth"
    @resize:move="handleResize"
    @resize:end="handleTreeResize"
  >
    <div class="tree-wrapper__content">
      <Tree />
      <TreeFilter />
    </div>
    <SubstituteItem :id="modalsId.SUBSTITUTE_AN_ITEM" />
    <RemoveFromImport :id="modalsId.REMOVE_FROM_IMPORT" />
  </vue-resizable>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VueResizable from 'vue-resizable';
import TreeFilter from '@/components/Demand/Tree/TreeFilter.vue';
import SubstituteItem from '@/components/Modals/SubstitutionRules/SubstituteItem.vue';
import RemoveFromImport from '@/components/Modals/SubstitutionRules/RemoveFromImport.vue';
import Tree from '@/components/Demand/Tree/Tree.vue';
import { uiTabByRoute, uiSettingsKeys } from '@/config/users/uiSettings.config';
import modalsId from '@/config/shared/modalsId.config';
import { customDebounce } from '@/helpers/shared/listeners';

export default {
  name: 'TreeWrapper',
  components: {
    VueResizable,
    TreeFilter,
    Tree,
    SubstituteItem,
    RemoveFromImport
  },
  data() {
    return {
      width: null,
      modalsId
    };
  },
  computed: {
    ...mapState({
      treeState: (state) => state.demand.tree,
      isFetching: (state) => state.demand.tree.is_fetching,
      ui_settings: (state) => state.user.ui_settings
    }),
    isResizableMin() {
      return this.width === this.vueResizableConfig.minWidth;
    },
    demandUiSettings() {
      return this.ui_settings[uiTabByRoute[this.$sl_routeName]];
    },
    vueResizableConfig() {
      return {
        active: ['r'],
        width: this.demandUiSettings?.[uiSettingsKeys.SPECIFIC]?.[uiSettingsKeys.DEMAND_TREE_WIDTH] || 230,
        minWidth: 1
      };
    }
  },
  methods: {
    ...mapActions({
      changeUiSettings: 'user/changeUiSettings'
    }),
    handleResize: customDebounce(function(data) {
      this.width = data.width;
    }, 200),
    handleTreeResize({ width }) {
      this.changeUiSettings({
        key: uiSettingsKeys.SPECIFIC,
        value: {
          [uiSettingsKeys.DEMAND_TREE_WIDTH]: width
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/components/demand/tree/tree-wrapper.scss";
</style>
