var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('SlContextMenu',{ref:"contextMenu",attrs:{"element-id":"sl-tree-context-menu","options":_vm.treeContextMenuParsedOptions,"data-shortcut-tree":""},on:{"option-clicked":_vm.handleOptionClick},scopedSlots:_vm._u([{key:_vm.contextMenuKeys.COPY_ITEM_TO_CLIPBOARD,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.COPY_ITEM_CODE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.COPY_ITEM_CODE, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.COPY_CODE.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.APPROVE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.APPROVE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.APPROVE, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.SET_APPROVE_UNAPPROVE.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.NEEDS_ATTENTION,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.NEEDS_ATTENTION, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.NEEDS_ATTENTION, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.SET_ATTENTION.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.CHECKED,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.CHECK, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.CHECK, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.SET_CHECKED.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_INVENTORY,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_IR, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_IR, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_DC,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DC, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_DC, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.FIND_IN_REPORTS,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_FR, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.FIND_IN_FR, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.ITEM_BOM,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.ITEM_BOM, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.ITEM_BOM, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.OPEN_MATERIAL_PRODUCT_TREE.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.REMOVE_FROM_IMPORT,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.REMOVE_FROM_IMPORT, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.REMOVE_FROM_IMPORT, _vm.fgsActionTypes.ENABLED)},scopedSlots:_vm._u([{key:"shortcut",fn:function(){return _vm._l((_vm.shortcutsConfig.REMOVE_FROM_IMPORT.shortcut),function(value){return _c('SlShortcut',{key:value,attrs:{"value":value}})})},proxy:true}],null,true)},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.SUBSTITUTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.SUBSTITUTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":_vm.isSubstituteDisabled}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.ADD_NOTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.ADD_NOTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.ADD_NOTE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.EDIT_NOTE,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.EDIT_NOTE, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.EDIT_NOTE, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.CLEAR_OVERRIDES,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
return [(_vm.getActionFgs(_vm.contextMenuFgs.CLEAR_OVERRIDES, _vm.fgsActionTypes.VISIBLE))?_c('OptionItem',{attrs:{"handler":handler,"disabled":!_vm.getActionFgs(_vm.contextMenuFgs.CLEAR_OVERRIDES, _vm.fgsActionTypes.ENABLED)}},[_vm._v(" "+_vm._s(name)+" ")]):_vm._e()]}},{key:_vm.contextMenuKeys.CLEAR_SETTINGS,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
var subList = ref.subList;
return [_c('OptionItem',{attrs:{"handler":handler}},[_c('v-menu',{attrs:{"content-class":"tree-context-menu__sublist","open-delay":200,"close-delay":200,"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"icon-wrapper"},[_c('icon',{staticClass:"size-16 rotate--90",attrs:{"data":require("@icon/arrow-down.svg")}})],1)],1)],1)]}}],null,true)},[_c('v-list',_vm._l((subList),function(subItem){return _c('v-list-item',{key:subItem.key,staticClass:"tree-context-menu__sublist-item",attrs:{"disabled":!_vm.getActionFgs(_vm.contextMenuFgs[subItem.fgs], _vm.fgsActionTypes.ENABLED)},on:{"click":function($event){return _vm.handleClearSettings(subItem.key)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}),1)],1)],1)]}},{key:_vm.contextMenuKeys.EXPORT_NODE_TO_XLSX,fn:function(ref){
var handler = ref.handler;
var name = ref.name;
var subList = ref.subList;
return [(_vm.isExportToXlsxVisible)?_c('OptionItem',{attrs:{"handler":handler,"disabled":_vm.isExportToXlsxDisabled}},[_c('v-menu',{attrs:{"content-class":"tree-context-menu__sublist","open-delay":200,"close-delay":200,"open-on-hover":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(name)+" ")]),_c('div',{staticClass:"icon-wrapper"},[_c('icon',{staticClass:"size-16 rotate--90",attrs:{"data":require("@icon/arrow-down.svg")}})],1)],1)],1)]}}],null,true)},[_c('v-list',_vm._l((subList),function(subItem){return _c('v-list-item',{key:subItem.key,staticClass:"tree-context-menu__sublist-item",attrs:{"disabled":!_vm.getActionFgs(_vm.contextMenuFgs[subItem.key], _vm.fgsActionTypes.ENABLED)},on:{"click":function($event){return _vm.handleExportNodeToXlsx(subItem.visitLeafs)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(subItem.title)+" ")])],1)],1)}),1)],1)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }